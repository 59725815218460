import cloneDeep from 'lodash/cloneDeep';
import { Injectable } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

import { SearchResultStore } from '../../../../dynamic-page-container/services/store/search-result.store';
import { LoadingMatrixStore } from './store/loading-matrix.store';
import { NightDateArea } from '../classes/night-date-table/night-date-area';
import { DateRange } from '@classes/range/date-range';
import { HotelPageInfoStore } from '../../../services/hotel-page-info.store';
import { HotelSearchService } from '../../hotel-availability/services/hotel-search.service';

import { LoadingStatus } from '@modules/tours/table-toolkit/interfaces/loading-status';
import { SearchParams } from '@interfaces/modules/tours/search';

@Injectable()
export class OffersUploaderService {
  constructor(
    private searchResultStore: SearchResultStore,
    private pageInfoStore: HotelPageInfoStore,
    private ndLoadingMatrix: LoadingMatrixStore,
    private searchService: HotelSearchService
  ) { }

  uploadOffers(area: NightDateArea): void {
    const actual = this.enrichSearchParams(area);

    this.ndLoadingMatrix.updateMatrix(area, LoadingStatus.InProcess);
    this.searchService.search(actual);
  }

  private enrichSearchParams(area: NightDateArea): SearchParams {
    const result = cloneDeep(this.searchResultStore.state.actualSearch.search);
    const hotelId = this.pageInfoStore.state.hotelInfo?.hotelId;

    if (area.dates) {
      const { from, to }: DateRange = area.dates;
      result.dates = {
        checkIn: from ? from.toISOString() : result.dates?.checkIn,
        checkOut: to ? to.toISOString() : result.dates?.checkOut
      };
    }

    if (!result.duration?.from) {
      throw new Error('Duration should have from value');
    }

    if (area.nights) {
      result.duration = {
        from: area.nights.from || result.duration.from,
        to: area.nights.to || result.duration?.to
      };
    }

    result.destination = hotelId ? { id: hotelId, type: ToursSearch.DestinationType.Hotel } : undefined;

    if (result.hotels) {
      delete result.hotels;
    }

    if (result.resorts) {
      delete result.resorts;
    }

    return result;
  }
}
