import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Lang2 } from '@temabit/package-types';

@Pipe({
  name: 'fieldTranslation',
  pure: false
})
export class FieldTranslationPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef) { }

  transform<T>(obj: { [Lang2.Ru]?: T, [Lang2.Uk]?: T }): T | undefined {
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(() => this.cd.markForCheck());
    }

    return obj[this.translateService.currentLang as Lang2];
  }

  ngOnDestroy(): void {
    if (this.onLangChange) {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }
}
