import { Pipe, PipeTransform } from '@angular/core';

import { MimeType } from '@temabit/package-types';

@Pipe({
  name: 'mimeTypesExtensions'
})
export class MimeTypesExtensionsPipe implements PipeTransform {
  transform(types: MimeType[]): string {
    return types
      .map(type => type.split('/'))
      .map(([, ext]) => ext)
      .filter((ext): ext is string => Boolean(ext))
      .join(', ');
  }
}
