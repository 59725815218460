import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToursSearch } from '@temabit/package-types';

import { InformationStore } from '@services/store/information.store';

@Pipe({
  name: 'departureCitySelector'
})
export class DepartureCitySelectorPipe implements PipeTransform {
  constructor(private readonly informationStore: InformationStore) { }

  transform(id: number): Observable<ToursSearch.DepartureCity | undefined> {
    return this.informationStore.onChanges('departureCities')
      .pipe(
        map(cities => cities.find(city => city.id === id))
      );
  }
}
