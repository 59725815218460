import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Images, Lang2 } from '@temabit/package-types';

import { MainPageBlockWithResizes } from '../interfaces/main-page-block';

@Pipe({
  name: 'blockImageLinks'
})
export class BlockImageLinksPipePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(block: MainPageBlockWithResizes): Images.ResizesLinks | undefined {
    const { imageId, imageIds, imageResizes } = block;
    const pickedImageId = imageId ?? imageIds?.[this.translateService.currentLang as Lang2];
    const resizes = pickedImageId && imageResizes?.[pickedImageId];

    return resizes || undefined;
  }
}
