import intersectionWith from 'lodash/intersectionWith';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { format, isValid } from 'date-fns';

import { ToursSearch } from '@temabit/package-types';

import { ChosenFlights, GlobalFilter } from '../interfaces/offer-management';

export function extractDisplayFilterFromOffer(offer: ToursSearch.Offer) {
  return {
    food: offer.food,
    room: offer.room,
    fromDate: offer.dateRange?.from,
    nights: offer.nights
  };
}

export function extractFlightsFromOffer(offer: ToursSearch.Offer): ChosenFlights {
  return {
    from: offer.flights?.from?.[0],
    to: offer.flights?.to?.[0]
  };
}

export function buildFilter(offers: ToursSearch.Offer[]): GlobalFilter {
  return {
    operatorIds: extractOperatorIds(offers),
    rooms: extractRooms(offers),
    airlines: extractAirlines(offers),
    flightFromTimes: extractFlightFromDepartureTime(offers)
  };
}

export function findOfferById(offers: ToursSearch.Offer[], offerId: string) {
  return offers.find(o => o.offerId === offerId);
}

export function findFlightsRangeByFlightCodes(offer: ToursSearch.Offer, flightCodes: { from?: string, to?: string }): ChosenFlights {
  const { from = '', to = '' } = flightCodes;

  return {
    from: (offer.flights.from ?? []).find(flight => flight.code === from),
    to: (offer.flights.to ?? []).find(flight => flight.code === to)
  };
}

export function filterOffers(offers: ToursSearch.Offer[], { rooms, airlines, operatorIds, flightFromTimes }: GlobalFilter) {
  return offers.filter(({ flights, room, operatorId }) => {
    return (rooms.length === 0 || rooms.includes(room))
      && (operatorIds.length === 0 || operatorIds.includes(operatorId))
      && (airlines.length === 0 || isAirlinesIntersects(flights, airlines))
      && (flightFromTimes.length === 0 || isTimesIntersects(flights, flightFromTimes));
  });
}

function isTimesIntersects(flights: ToursSearch.Offer['flights'], timesFilter: GlobalFilter['flightFromTimes']): boolean {
  const flightFromDates = flights.from
    .map(({ timeRange }) => timeRange.from)
    .filter((date): date is Date => isValid(date));
  const flightFromTimesIntersection = intersectionWith(flightFromDates, timesFilter, (date, time) => format(date, 'HH:mm') === time);

  return flightFromTimesIntersection.length !== 0;
}

function isAirlinesIntersects(flights: ToursSearch.Offer['flights'], airlines: GlobalFilter['airlines']): boolean {
  const allFlights = [...flights.from, ...flights.to];
  const airlinesIntersection = intersectionWith(allFlights, airlines, (flight, airline) => flight.line === airline);

  return airlinesIntersection.length !== 0;
}

export function extractFlightFromDepartureTime(offers: ToursSearch.Offer[]): string[] {
  const dates = offers
    .reduce<ToursSearch.Flight[]>((flights, { flights: { from } }) => [...flights, ...from], [])
    .map(flight => flight.timeRange.from ? format(flight.timeRange.from, 'HH:mm') : undefined)
    .filter((flight): flight is string => flight !== undefined);

  return uniq(dates).sort();
}

export function extractRooms(offers: ToursSearch.Offer[]): string[] {
  return uniqBy(offers, 'room').map(offer => offer.room);
}

export function extractOperatorIds(offers: ToursSearch.Offer[]): number[] {
  return uniqBy(offers, 'operatorId').map(offer => offer.operatorId);
}

export function extractAirlines(offers: ToursSearch.Offer[]): string[] {
  const flights = offers
    .reduce<ToursSearch.Flight[]>((flights, { flights: { from, to } }) => [...flights, ...from, ...to], []);

  return uniqBy(flights, 'line').map(flight => flight.line ?? '');
}
