import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Lang2, ToursSearch } from '@temabit/package-types';

import { environment } from '@environment';
import { convertPricesInHotelProposals, convertSearchParamsToSearchQuery } from '@helpers/converter.helper';

import { SearchParams } from '@interfaces/modules/tours/search';
import { GetSuggestionsResponse, RawSuggestion, SearchResponse } from '@interfaces/services/api/tours-search.endpoint';

@Injectable({
  providedIn: 'root'
})
export class ToursSearchEndpoint {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  search(search: SearchParams, lang: Lang2, page: number): Observable<SearchResponse> {
    const query: ToursSearch.Query = convertSearchParamsToSearchQuery(search);

    return this.http.post<SearchResponse>(`${this.apiUrl}/toursSearch/search`, { query, lang, pageNumber: page })
      .pipe(
        tap(res => convertPricesInHotelProposals(res.result.hotels))
      );
  }

  result(searchId: string, page: number, lang: Lang2): Observable<ToursSearch.Actions.GetResultResult> {
    return this.http.post<ToursSearch.Actions.GetResultResult>(`${this.apiUrl}/toursSearch/getResult`, { searchId, page, lang })
      .pipe(
        tap(res => convertPricesInHotelProposals(res.result.hotels))
      );
  }

  getSuggestions(lang: Lang2, text: string, withPrice: boolean = true, types: ToursSearch.DestinationType[]): Observable<RawSuggestion[]> {
    return this.http.post<GetSuggestionsResponse>(`${this.apiUrl}/toursSearch/getSuggestions`, { lang, text, withPrice, types }).pipe(
      map((res: GetSuggestionsResponse) => res.suggestions)
    );
  }

  getDepartureCities(lang: Lang2, locationId?: number): Observable<ToursSearch.DepartureCity[]> {
    const params: Record<string, string> = { lang };
    if (locationId) {
      params.locationId = locationId.toString();
    }

    return this.http.get<ToursSearch.Actions.GetDepartureCitiesResult>(`${this.apiUrl}/toursSearch/departureCities`, { params })
      .pipe(
        map(res => res.departureCities)
      );
  }
}
