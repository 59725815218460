import { ToursCatalog, ToursSearch } from '@temabit/package-types';

import { generateRequestState } from '@helpers/endpoint.helper';

import { StateWithRequests } from '@interfaces/helpers/store-request-state-updater';

export class HotelsPageState implements StateWithRequests {
  page = 0;
  hasAvailableHotels = true;
  hotels: ToursSearch.HotelProposal[] = [];
  requests = {
    [ToursCatalog.Actions.Name.GetHotelsForPage]: generateRequestState()
  };
}
