import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { icon, latLng, MapOptions, marker, tileLayer } from 'leaflet';

import { Location } from '@temabit/package-types';

import { getMarkerIconOptions } from '@constants/map-marker-icon';

@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapCardComponent {
  public options!: MapOptions;
  public isMapVisible: boolean = false;

  @Input() set location(val: Location) {
    if (val.coordinates) {
      this.options = this.initLeafletOptions(val);
    }
  }

  showMap(): void {
    this.isMapVisible = true;
  }

  private initLeafletOptions({ coordinates, zoom }: Location): MapOptions {
    return {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
        marker(
          coordinates,
          { icon: icon(getMarkerIconOptions()) }
        )
      ],
      zoom: zoom || 15,
      center: latLng(...coordinates),
    };
  }
}
