import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToursCatalog } from '@temabit/package-types';

import { ToursCatalogEndpoint } from '../api/tours-catalog.endpoint';
import { TourOperatorsState } from './tour-operators.state';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TourOperatorsStore extends Store<TourOperatorsState> {
  constructor(private readonly toursCatalogEndpoint: ToursCatalogEndpoint) {
    super(new TourOperatorsState());
  }

  init(): void {
    this.loadTourOperators();
  }

  onTourOperator(id: number): Observable<ToursCatalog.SiteTourOperator | null> {
    return this.onChanges('tourOperators')
      .pipe(
        map(operators => operators.find(o => o.id === id) || null)
      );
  }

  private loadTourOperators(): void {
    this.toursCatalogEndpoint.getTourOperators()
      .pipe(untilDestroyed(this))
      .subscribe(({ tourOperators }) => this.patchState(tourOperators, 'tourOperators'));
  }
}
