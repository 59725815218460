import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes, formatDuration } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { uk } from 'date-fns/locale';
import { Subscription } from 'rxjs';

import { Lang2 } from '@temabit/package-types';

import { pickDateLocale } from '@helpers/date.helper';

@Pipe({
  name: 'flightDuration',
  pure: false
})
export class FlightDurationPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) { }

  transform(from: Date, to: Date): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }

    return this.flightDuration(from, to);
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }

  private flightDuration(from: Date, to: Date) {
    const diff = differenceInMinutes(to, from);
    const hour = 60;

    return formatDuration(
      {
        hours: Math.floor(diff / hour),
        minutes: diff % hour
      },
      {
        locale: pickDateLocale(this.translate.currentLang as Lang2) ?? uk
      }
    );
  }
}
