import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { TableDrivenFilterStore } from '../../services/store/table-driven-filter.store';
import { NightDateStore } from '../../services/store/night-date.store';
import { filterOfferByFoodRoom } from '../../helpers/night-date.helper';
import { CellBase } from '../../classes/cell-base';
import { LoadingMatrixStore } from '../../services/store/loading-matrix.store';
import { filterOfferByNightDate } from '../../helpers/room-food-helper';
import { HotelSearchService } from '../../../hotel-availability/services/hotel-search.service';

import { CellColor } from '@modules/tours/table-toolkit/interfaces/cell-color';
import { LoadingStatus } from '@modules/tours/table-toolkit/interfaces/loading-status';
import { CellLayout } from '../../interfaces/cell-layout.enum';

@Component({
  selector: 'app-night-date-cell',
  templateUrl: './night-date-cell.component.html',
  styleUrls: ['./night-date-cell.component.scss'],
  styles: [':host { width: 100%; height: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NightDateCellComponent extends CellBase {
  CellLayout = CellLayout;

  constructor(
    selectionStore: SelectionStore,
    search: HotelSearchService,
    private filterStore: TableDrivenFilterStore,
    private availableDatesStore: NightDateStore,
    private ndLoadingMatrixService: LoadingMatrixStore
  ) {
    super(selectionStore, search);
  }

  chooseType(): CellColor | undefined {
    if (this.offer === null && !this.isLoaded()) {
      return CellColor.LoadingAvailable;
    }

    return super.chooseType();
  }

  isUnfiltered(): boolean {
    return !filterOfferByFoodRoom(this.offer, this.filterStore.state);
  }

  isLoaded(): boolean {
    return this.getCellStatus() === LoadingStatus.Loaded;
  }

  isInProcess(): boolean {
    return this.getCellStatus() === LoadingStatus.InProcess;
  }

  isCheapest(): boolean {
    return this.offer?.priceUAH.toNumber() === this.availableDatesStore.state.cheapestPrice;
  }

  isSelectedCell(): boolean {
    return filterOfferByNightDate(this.offer, this.filterStore.state);
  }

  protected getLayout(offer: ToursSearch.Offer | null): CellLayout {
    if (!this.isLoaded() && !this.isInProcess()) return CellLayout.PossibleLoading;

    if (this.offer) {
      if (this.isUnfiltered()) return CellLayout.AdditionalInfo;
    } else {
      if (this.isLoaded()) return CellLayout.EmptyOffer;
      if (this.isInProcess()) return CellLayout.Loading;
    }

    return CellLayout.None;
  }

  private getCellStatus(): LoadingStatus | null {
    const table = this.availableDatesStore.state.table;

    return this.ndLoadingMatrixService.getStatus(
      table.colHeader.getSegment(this.cell.position.x),
      table.rowHeader.getSegment(this.cell.position.y)
    );
  }
}
