import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { Images } from '@temabit/package-types';

import { OverlayBottomDirective } from './overlay-bottom.directive';
import { OverlayTopDirective } from './overlay-top.directive';

@Component({
  selector: 'app-overlaid-image',
  templateUrl: './overlaid-image.component.html',
  styleUrls: ['./overlaid-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlaidImageComponent {
  @Input() resizesLinks?: Images.ResizesLinks;
  @Input() imgAlt: string = 'Hotel Name';
  @Input() imageFit: 'contain' | 'cover' = 'cover';

  @ContentChild(OverlayTopDirective, { read: TemplateRef }) topTemplate!: TemplateRef<any>;
  @ContentChild(OverlayBottomDirective, { read: TemplateRef }) bottomTemplate!: TemplateRef<any>;
}
