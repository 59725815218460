import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

@Pipe({
  name: 'destinationGroup'
})
export class DestinationGroupPipe implements PipeTransform {

  transform(groupName: ToursSearch.DestinationType): string {
    switch (groupName) {
      case ToursSearch.DestinationType.Country:
        return 'SearchComponent.Location.GroupLabels.Country';
      case ToursSearch.DestinationType.Resort:
        return 'SearchComponent.Location.GroupLabels.Resort';
      case ToursSearch.DestinationType.Hotel:
        return 'SearchComponent.Location.GroupLabels.Hotel';
      default:
        throw new Error('Incorrect DestinationType');
    }
  }
}
