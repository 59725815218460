import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';

import { ToursSearch } from '@temabit/package-types';

import { valueAccessor } from '@helpers/angular.helper';
import { SearchFormStore } from '@services/store/search-form.store';
import { SearchFormState } from '@services/store/search-form.state';

@Component({
  selector: 'app-tour-duration',
  templateUrl: './tour-duration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(TourDurationComponent)]
})
export class TourDurationComponent implements ControlValueAccessor {
  public durationsList$: Observable<SearchFormState['selectors']['duration']> = this.searchForm.onChanges('selectors', 'duration');

  private selectedDurationValue!: ToursSearch.Duration;

  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  constructor(private searchForm: SearchFormStore, private cd: ChangeDetectorRef) { }

  get selectedDuration(): ToursSearch.Duration {
    return this.selectedDurationValue;
  }

  set selectedDuration(duration: ToursSearch.Duration) {
    this.selectedDurationValue = { ...duration };
    this.onTouched();
    this.onChange({ ...duration });
  }

  comparator(option: ToursSearch.Duration, selected: ToursSearch.Duration): boolean {
    return isEqual(option, selected);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(duration: ToursSearch.Duration): void {
    this.selectedDurationValue = { ...duration };
    this.cd.markForCheck();
  }
}
