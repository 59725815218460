import { Pipe, PipeTransform } from '@angular/core';

import { BackOffice, Lang2 } from '@temabit/package-types';

import { Translatable } from '@classes/translatable';
import { WorkHoursNormalizer } from './work-hours-normalizer';
import { ScheduleDemonstrator } from './schedule-demonstrator';

@Pipe({
  name: 'workSchedule',
  pure: false
})
export class WorkSchedulePipe extends Translatable implements PipeTransform {
  transform(schedule: BackOffice.OfficeWorkHours[]): string[] {
    this.ensureSubscriptionOnLangChange();
    const groups = new WorkHoursNormalizer(schedule).groupWeekdaysByWorkHours();

    return new ScheduleDemonstrator(this.translate.currentLang as Lang2, groups).scheduleToString();
  }
}
