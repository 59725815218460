import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { differenceInCalendarDays } from 'date-fns';

import { Lang2, ToursSearch } from '@temabit/package-types';

import { ToursSearchEndpoint } from '../api/tours-search.endpoint';
import { SocketIOService } from '../socket-io.service';
import { Search } from '@classes/search/search';
import { SearchCollection } from '@classes/search/search-collection';
import { Interval } from '@classes/search/interval';
import { GlobalSearchService } from './global-search.service';
import { IrrelevantSearchError } from './errors/irrelevant-search.error';
import { PlatformService } from '../platform.service';

import { SearchParams } from '@interfaces/modules/tours/search';
import { ToursSearchResult } from '@interfaces/tours-search-result';

@UntilDestroy()
@Injectable()
export class ToursSearchService implements OnDestroy {
  private readonly searchCollection = new SearchCollection();
  private readonly searchFallback = new Interval(() => this.searchCollection.forEach(search => this.getResult(search)));

  constructor(
    private globalSearch: GlobalSearchService,
    private toursSearch: ToursSearchEndpoint,
    private socket: SocketIOService,
    private translate: TranslateService,
    private platform: PlatformService
  ) {
    this.init();
  }

  init(): void {
    if (this.platform.isServer()) {
      return;
    }
    this.onSearchResultsPageReady();
    this.initSearchFallback();
    this.searchCollection.destroySearch$
      .pipe(untilDestroyed(this))
      .subscribe(id => this.socket.leave(id));
  }

  search(params: SearchParams, id?: string, page: number = 1): Observable<ToursSearchResult> {
    if (this.platform.isServer()) {
      return of();
    }
    if (this.isSearchIrrelevant(params)) {
      return throwError(new IrrelevantSearchError(params));
    }

    if (id) {
      const search = this.searchCollection.get(id);
      if (search) {
        return search.asObservable();
      }
    }

    return this.toursSearch.search(params, this.translate.currentLang as Lang2, page)
      .pipe(
        switchMap(({ searchId, result, status }) => {
          if (status === ToursSearch.ResultStatus.Ready) {
            return of({ page, params, id: searchId, res: { result, status } });
          }
          const search = this.add({ page, params, id: searchId, res: { result, status } });

          return search.asObservable();
        })
      );
  }

  finishActiveSearches(): void {
    this.searchCollection.destroy();
  }

  ngOnDestroy(): void {
    this.searchCollection.destroy();
  }

  private isSearchIrrelevant({ dates }: SearchParams): boolean {
    return Boolean(
      dates?.checkIn
      && differenceInCalendarDays(new Date(dates.checkIn), new Date()) < 1
    );
  }

  private add(resultState: ToursSearchResult): Search {
    const search = Search.from(resultState);
    this.searchCollection.set(search);
    this.socket.join(search.getSearchId());
    this.getResult(search);

    return search;
  }

  private onSearchResultsPageReady() {
    this.socket.searchResultStatus$
      .pipe(untilDestroyed(this))
      .subscribe(({ searchId, status }) => {
        const search = this.searchCollection.get(searchId);
        if (search) {
          if (status === ToursSearch.ResultStatus.Ready) {
            this.getResult(search);
          } else if (status === ToursSearch.ResultStatus.Failed) {
            this.searchCollection.consumeResult(searchId, { status, result: { hotels: [], totalCount: 0 } });
          }
        } else {
          this.socket.leave(searchId);
        }
      });
  }

  private initSearchFallback() {
    this.socket.disconnect$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.searchFallback.start());

    this.socket.connect$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.searchFallback.stop();
        this.searchCollection.forEach((search) => {
          this.socket.join(search.getSearchId());
          this.getResult(search);
        });
      });
  }

  private getResult(search: Search): void {
    this.toursSearch.result(search.getSearchId(), search.getPageNumber(), this.translate.currentLang as Lang2)
      .pipe(untilDestroyed(this))
      .subscribe(response => this.searchCollection.consumeResult(search.getSearchId(), response));
  }
}
