import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import groupBy from 'lodash/groupBy';

import { BackOffice } from '@temabit/package-types';

import { OfficesStore } from '@services/store/offices.store';

interface City {
  name: string;
  translit: string;
  offices: BackOffice.SiteOffice[];
}

@UntilDestroy()
@Component({
  selector: 'app-offices-card',
  templateUrl: './offices-card.component.html',
  styleUrls: ['./offices-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OfficesStore]
})
export class OfficesCardComponent implements OnInit {
  public selectedCity?: City;
  public cities$: Observable<City[]> = this.officesStore.onChanges('list').pipe(
    map(offices => this.groupOfficesByCity(offices)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private baseOptions: OwlOptions = {
    responsive: {
      0: { items: 1 }
    },
    autoplayTimeout: 5000
  };

  constructor(private readonly officesStore: OfficesStore) { }

  getOptions(): OwlOptions {
    const isAutoplay = this.selectedCity && this.selectedCity.offices.length > 1;

    return { ...this.baseOptions, autoplay: isAutoplay, loop: isAutoplay };
  }

  ngOnInit(): void {
    this.officesStore.init();
    this.cities$
      .pipe(untilDestroyed(this))
      .subscribe(([defaultCity]) => this.selectedCity = defaultCity);
  }

  private groupOfficesByCity(offices: BackOffice.SiteOffice[]): City[] {
    const grouped = groupBy(offices, office => office.location.city.translit);

    return Object.values(grouped)
      .map(offices => ({ offices, name: offices[0].location.city.name, translit: offices[0].location.city.translit }));
  }
}
