import { Pipe, PipeTransform } from '@angular/core';

import { ToursCatalog } from '@temabit/package-types';

@Pipe({
  name: 'ratingTranslateMapper'
})
export class RatingTranslateMapperPipe implements PipeTransform {
  private readonly dictionary: { [code in ToursCatalog.VoteCode]: string } = {
    [ToursCatalog.VoteCode.Rooms]: 'HotelOverviewPage.Rating.Votes.Rooms',
    [ToursCatalog.VoteCode.Cleaning]: 'HotelOverviewPage.Rating.Votes.Cleaning',
    [ToursCatalog.VoteCode.Service]: 'HotelOverviewPage.Rating.Votes.Service',
    [ToursCatalog.VoteCode.Food]: 'HotelOverviewPage.Rating.Votes.Food',
    [ToursCatalog.VoteCode.Infrastructure]: 'HotelOverviewPage.Rating.Votes.Infrastructure'
  };

  transform(label: ToursCatalog.VoteCode): string {
    return this.dictionary[label];
  }
}
