import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToursSearch } from '@temabit/package-types';

import { BookOfferModalComponent } from '../book-offer-modal/book-offer-modal.component';

import { ChosenFlights } from '../../interfaces/offer-management';

@Component({
  selector: 'app-selected-offer-panel',
  templateUrl: './selected-offer-panel.component.html',
  styleUrls: ['./selected-offer-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedOfferPanelComponent {
  @Input() selectedOffer?: ToursSearch.Offer;
  @Input() selectedFlights?: ChosenFlights;

  constructor(private modalService: NgbModal, private injector: Injector) { }

  bookSelectedOffer(): void {
    this.modalService.open(BookOfferModalComponent, { injector: this.injector, size: 'xl' });
  }
}
