import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

@Component({
  selector: 'app-food-icon',
  templateUrl: './food-icon.component.html',
  styleUrls: ['./food-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoodIconComponent {
  @Input() value = ToursSearch.Food.AI;
}
