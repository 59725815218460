import { ToursSearch } from '@temabit/package-types';

import { TableDrivenFilter } from '@pages/tours/hotel-overview-page/modules/available-offers/interfaces/table-driven-filter';

export class TableDrivenFilterState implements TableDrivenFilter {
  food?: ToursSearch.Food;
  room?: string;
  fromDate?: Date;
  nights?: number;
}
