import { JSONSchema } from '@ngx-pwa/local-storage';

import { Otpusk, ToursSearch } from '@temabit/package-types';

export const searchParamsSchema: JSONSchema = {
  type: 'object',
  properties: {
    destination: {
      type: 'object',
      properties: {
        id: { type: 'integer', minimum: 0 },
        fullName: { type: 'string' },
        type: { type: 'string' },
        name: { type: 'string' },
        weight: { type: 'integer', minimum: 0 },
        countryName: { type: 'string' },
        price: { type: 'number', minimum: 0 }
      }
    },
    hotels: {
      type: 'array',
      items: { type: 'integer', minimum: 0 }
    },
    resorts: {
      type: 'array',
      items: { type: 'integer', minimum: 0 }
    },
    departureCity: { type: 'integer', minimum: 0 },
    ratingMin: { type: 'integer', minimum: 0 },
    ratingMax: { type: 'integer', minimum: 0 },
    dates: {
      type: 'object',
      properties: {
        checkIn: {
          type: 'string',
          pattern: '^\\d{4}-\\d{2}-\\d{2}$'
        },
        checkOut: {
          type: 'string',
          pattern: '^\\d{4}-\\d{2}-\\d{2}$'
        }
      }
    },
    duration: {
      type: 'object',
      required: ['from', 'to'],
      properties: {
        from: { type: 'integer', minimum: 0 },
        to: { type: 'integer', minimum: 0 }
      }
    },
    tourists: {
      type: 'object',
      required: ['adults', 'children'],
      properties: {
        adults: { type: 'integer', minimum: 1, maximum: 4 },
        children: {
          type: 'array',
          items: { type: 'integer', minimum: 0, maximum: 17 }
        }
      }
    },
    foods: {
      type: 'array',
      items: { type: 'string', enum: Object.values(ToursSearch.Food) }
    },
    transports: {
      type: 'array',
      items: { type: 'string', enum: Object.values(ToursSearch.Transport) }
    },
    services: {
      type: 'array',
      items: { type: 'string', enum: Object.values(Otpusk.MainService) }
    },
    stars: {
      type: 'array',
      items: { type: 'string', enum: Object.values(ToursSearch.Stars) }
    },
    price: {
      type: 'object',
      properties: {
        min: { type: 'integer', minimum: 0 },
        max: { type: 'integer', minimum: 0 }
      }
    }
  }
};
