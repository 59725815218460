import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursCatalog } from '@temabit/package-types';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  @Input() items?: ToursCatalog.SiteSectionLink[];
}
