import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { Lang2 } from '@temabit/package-types';

import { extractUrlElements } from './url-matcher.helper';
import { config } from '../app-config.module';

export function chooseLangByUrl(url: string): Lang2 {
  const elements = extractUrlElements(url);

  return chooseLangByUrlElements(elements);
}

export function chooseLangByUrlElements(urlElements: string[]): Lang2 {
  if (urlElements.length === 0 || urlElements[0] === config.languages.primary) {
    return config.languages.primary;
  }

  return config.languages.secondary;
}

export function transformUrlByLang(url: string, lang: Lang2): string[] {
  const urlElements: string[] = extractUrlElements(url);

  return transformUrlElementsByLang(urlElements, lang);
}

export function transformUrlElementsByLang(elements: string[], lang: Lang2): string[] {
  const urlElements: string[] = sanitizeUrlSegments(elements);

  return transformSegments(urlElements, lang);
}

export function transformSegments(segments: string[], lang: Lang2): string[] {
  if (lang === config.languages.primary) {
    return transformSegmentsForDefaultLang(segments, lang);
  }

  if (lang === config.languages.secondary) {
    return transformSegmentsForSecondaryLang(segments, lang);
  }

  return segments;
}

export function sanitizeUrlSegments(segments: string[]): string[] {
  if (!segments.length || segments.length === 1 && isLang(segments[0])) {
    return [''];
  }

  if (isLang(segments[0])) {
    return segments.slice(1);
  }

  return segments;
}

function transformSegmentsForDefaultLang(segments: string[], lang: Lang2): string[] {
  if (segments.length === 1 && segments[0] === '') {
    return segments;
  }
  segments.unshift(lang);

  return segments;
}

function transformSegmentsForSecondaryLang(segments: string[], lang: Lang2): string[] {
  if (segments.length === 1 && segments[0] === '') {
    return [lang];
  }

  return segments;
}

function isLang(lang: string): lang is Lang2 {
  return Object.values(Lang2).includes(lang as Lang2);
}

export function transformLinkWithCurrentLang(link: string, lang: Lang2, separator = '/'): string {
  const urlElements = extractUrlElements(link);

  return separator + transformUrlElementsByLang(urlElements, lang).join(separator);
}

export function onLangChange(service: TranslateService): Observable<Lang2> {
  return service.onLangChange
    .pipe(
      startWith({ lang: service.currentLang }),
      map(({ lang }) => lang),
      distinctUntilChanged(),
      filter((lang): lang is Lang2 => isLang(lang))
    );
}

export function extractLangFromUrl(url: string): Lang2 {
  const elements = extractUrlElements(url);

  return chooseLangByUrlElements(elements);
}
