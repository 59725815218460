import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Lang2 } from '@temabit/package-types';

import { pickDateLocale } from '@helpers/date.helper';

@Pipe({
  name: 'flightDate',
  pure: false
})
export class FlightDatePipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;
  private formatterMap = {
    day: 'dd MMMM',
    time: 'HH:mm',
    default: 'dd MMMM HH:mm'
  };

  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) { }

  transform(value?: Date, type?: 'time' | 'day'): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }
    if (!value) {
      return '-';
    }

    return this.flightDate(value, type);
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }

  private flightDate(value: Date, type?: 'time' | 'day'): string {
    const locale = pickDateLocale(this.translate.currentLang as Lang2) ?? uk;

    return format(new Date(value), this.formatterMap[type ?? 'default'], { locale });
  }
}
