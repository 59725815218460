import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { ToursCatalog } from '@temabit/package-types';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-search-resorts-picker',
  templateUrl: './search-resorts-picker.component.html',
  providers: [valueAccessor(SearchResortsPickerComponent)]
})
export class SearchResortsPickerComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() resorts: ToursCatalog.SelectiveResort[] = [];

  private selectedResortsValue: number[] = [];
  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  get selectedResorts(): number[] {
    return this.selectedResortsValue;
  }

  set selectedResorts(selectedResorts: number[]) {
    this.onTouched();
    this.onChange(selectedResorts);
    this.selectedResortsValue = selectedResorts;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(resortsCode: number[]): void {
    this.selectedResortsValue = resortsCode;
  }
}
