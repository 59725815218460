import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Lang2 } from '@temabit/package-types';

import { MainPageBlockWithResizes } from '../../interfaces/main-page-block';

@Pipe({
  name: 'mainPageBlockHtml'
})
export class MainPageBlockHtmlPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(block: MainPageBlockWithResizes): string | undefined {
    return block.html?.[this.translate.currentLang as Lang2];
  }
}
