import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

import { searchSelectorsTranslations } from '@constants/translation-dictionaries';

import { Dictionary } from '@interfaces/modules/tours/search';

@Pipe({
  name: 'foodTranslationPath'
})
export class FoodTranslationPathPipe implements PipeTransform {
  private readonly searchSelectorsTranslations: Dictionary = searchSelectorsTranslations;

  transform(code?: ToursSearch.Food | null): string {
    return code ? `${this.searchSelectorsTranslations.foods}.${code}` : '';
  }
}
