import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { Lang2 } from '@temabit/package-types';

import { OfficesState } from './offices.state';
import { ErrorMessage } from '@constants/error-message.enum';
import { BackOfficeEndpoint } from '../api/back-office.endpoint';
import { NotificationService } from '../notification.service';

@UntilDestroy()
@Injectable()
export class OfficesStore extends Store<OfficesState> {
  constructor(
    private readonly backOffice: BackOfficeEndpoint,
    private readonly notification: NotificationService,
    private readonly translate: TranslateService
  ) {
    super(new OfficesState());
  }

  init(): void {
    this.translate.onLangChange
      .pipe(
        map(({ lang }) => lang),
        startWith(this.translate.currentLang),
        filter((lang): lang is Lang2 => Object.values<string>(Lang2).includes(lang)),
        switchMap(lang => this.backOffice.getOffices(lang)),
        untilDestroyed(this)
      )
      .subscribe({
        next: ({ offices }) => this.patchState(offices, 'list'),
        error: () => this.notification.error(ErrorMessage.OfficesLoadingFailure)
      });
  }
}
