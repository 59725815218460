import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Required } from '@temabit/package-utils';

import { sanitizeUrlSegments } from '@helpers/language.helper';
import { extractUrlElements } from '@helpers/url-matcher.helper';

@UntilDestroy()
@Directive({
  selector: '[routerLink][appActiveUrlPath]'
})
export class ActiveUrlPathDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Required @Input('appActiveUrlPath') activeClassName!: string;
  @Input() urlMatcher?: RegExp;
  @Input() cutLanguagePrefix = false;

  constructor(
    private readonly router: Router,
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
    private readonly routerLink: RouterLinkWithHref
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        startWith({ url: this.router.routerState.snapshot.url }),
        untilDestroyed(this)
      )
      .subscribe(({ url }) => this.update(url));
  }

  private update(url: string): void {
    const { href } = this.routerLink;
    const [pathname] = url.split('?');
    if (this.matcherTest(pathname) || pathname === href) {
      this.renderer.addClass(this.element.nativeElement, this.activeClassName);
    } else {
      this.renderer.removeClass(this.element.nativeElement, this.activeClassName);
    }
  }

  private matcherTest(pathname: string): boolean {
    if (!this.urlMatcher) {
      return false;
    }
    const targetPathname = this.cutLanguagePrefix ? this.createPathWithoutLangPrefix(pathname) : pathname;

    return this.urlMatcher.test(targetPathname);
  }

  private createPathWithoutLangPrefix(pathname: string): string {
    const segments = sanitizeUrlSegments(extractUrlElements(pathname));

    return  `/${segments.join('/')}`;
  }
}
