import { Lang2 } from '@temabit/package-types';

export default {
  manifestLinks: {
    [Lang2.Ru]: '/silpovoyage_ru.webmanifest',
    [Lang2.Uk]: '/silpovoyage.webmanifest'
  },
  social: {
    fbAppId: 'fb_app_id',
    fbAdmin: 'fb_admin',
    googleId: 'google_id',
    itRatingId: 'it_rating_id'
  }
} as const;
