import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Subscription } from 'rxjs';

import { Currency, ToursSearch } from '@temabit/package-types';

import { PriceTransformer } from '../classes/price-transformer';
import { PriceDisplayOptionsStore } from '@pages/tours/hotel-overview-page/modules/hotel-availability/services/store/price-display-options.store';

@Pipe({
  name: 'offerPrice',
  pure: false
})
export class OfferPricePipe implements PipeTransform, OnDestroy {
  private subscription?: Subscription;

  constructor(
    private store: PriceDisplayOptionsStore,
    private currencyPipe: CurrencyPipe,
    private ref: ChangeDetectorRef
  ) { }

  transform(offer?: ToursSearch.Offer | null, display?: 'all' | 'currency-only'): string {
    this.updateValue();

    if (offer == null) {
      return '';
    }

    let price: number = this.selectPriceByStoredCurrency(offer);
    price = this.processDisplayArg(offer, price, display);

    const currencyStringCode: string = this.store.state.values.currency.toString().toUpperCase();

    return this.currencyPipe.transform(price, currencyStringCode, 'symbol-narrow', '1.0-0') ?? '';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  private updateValue(): void {
    if (!this.subscription) {
      this.subscription = this.store.state$.subscribe(() => this.ref.markForCheck());
    }
  }

  private processDisplayArg(offer: ToursSearch.Offer, price: number, display?: 'all' | 'currency-only'): number {
    switch (display) {
      case 'currency-only':
        return price;
      case 'all':
      default:
        const transformer = new PriceTransformer(offer, price, this.store.state.values);

        return transformer.transform();
    }
  }

  private selectPriceByStoredCurrency(offer: ToursSearch.Offer): number {
    switch (this.store.state.values.currency) {
      case Currency.UAH:
        return offer.priceUAH.toNumber();
      case Currency.USD:
      default:
        return offer.price.toNumber();
    }
  }
}
