import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';

import { ToursSearch } from '@temabit/package-types';

import { NightDateState } from './night-date.state';
import { findCheapestPricePerNights } from '../../helpers/offers.helper';
import { AvailableOffersStore } from '../../../hotel-availability/services/store/available-offers.store';
import { NightDateTableFactoryService } from '../night-date-table-factory.service';
import { NightDateScrollPosition } from '../../classes/night-date-table/night-date-scroll-position';
import { TableDrivenFilterStore } from './table-driven-filter.store';
import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { LoadingMatrixStore } from './loading-matrix.store';
import { DateRange } from '@classes/range/date-range';
import { NumberRange } from '@classes/range/number-range';
import { NightDateArea } from '../../classes/night-date-table/night-date-area';
import { OffersUploaderService } from '../offers-uploader.service';

import { ScrollDirection } from '@modules/tours/table-toolkit/interfaces/scroll';
import { NightDateCell, NightDateTable } from '../../interfaces/night-date-table';

export const DEFAULT_DATE_FORMAT = 'dd.LL (EEEEEE)';
export const XS_DATE_FORMAT = 'dd.LL';
export const DEFAULT_NIGHT_DATE_TABLE_SIDE = 5;
export const XS_NIGHT_DATE_TABLE_SIDE = 3;

@UntilDestroy()
@Injectable()
export class NightDateStore extends Store<NightDateState> {
  private scrollPosition!: NightDateScrollPosition;
  private tableSide = DEFAULT_NIGHT_DATE_TABLE_SIDE;

  constructor(
    private filterStore: TableDrivenFilterStore,
    private availableOffersStore: AvailableOffersStore,
    private ndTableFactory: NightDateTableFactoryService,
    private offersUploaderService: OffersUploaderService,
    private selectionStore: SelectionStore,
    private ndLoadingMatrix: LoadingMatrixStore
  ) {
    super(new NightDateState());
  }

  scroll(direction: ScrollDirection): void {
    this.scrollPosition.scroll(direction);
    this.createTable();
    this.updateOffersIfNeeded();
  }

  changeTableSide(side: number): void {
    this.tableSide = side;
    this.scrollPosition = new NightDateScrollPosition(this.availableOffersStore.state.offers, side);
    const { selectedOffer } = this.selectionStore.state;
    if (selectedOffer) {
      this.scrollPosition.center(selectedOffer.dateRange.from, selectedOffer.nights);
    }
    this.createTable();
  }

  checkAndUpload(cell: NightDateCell): void {
    if (this.isLoaded(cell)) return;

    const displayedArea = this.currentTableArea();
    const loadArea = this.ndLoadingMatrix.cutLoadedAreas(displayedArea);
    this.offersUploaderService.uploadOffers(loadArea);
  }

  init(): void {
    this.availableOffersStore.onChanges('offers')
      .pipe(untilDestroyed(this))
      .subscribe((offers) => {
        this.ensureScroll(offers);
        this.createTable();
      });

    merge(
      this.filterStore.onChanges('room'),
      this.filterStore.onChanges('food')
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => this.createTable());

    this.selectionStore.onChanges('selectedOffer')
      .pipe(untilDestroyed(this))
      .subscribe(selected => selected && this.jumpToOffer(selected));
  }

  private currentTableArea(): NightDateArea {
    return NightDateArea.fromArrays(
      this.state.table.colHeader.getSegments(),
      this.state.table.rowHeader.getSegments()
    );
  }

  private isLoaded(cell: NightDateCell): boolean {
    return this.ndLoadingMatrix.isLoaded(
      this.state.table.colHeader.getSegment(cell.position.x),
      this.state.table.rowHeader.getSegment(cell.position.y)
    );
  }

  private createTable(): void {
    const offers = this.availableOffersStore.state.offers;

    if (!offers.length) return;

    this.setState({
      ...this.state,
      table: this.buildCombinedTable(offers),
      cheapestPrice: findCheapestPricePerNights(offers)
    });
  }

  private buildCombinedTable(offers: ToursSearch.Offer[]): NightDateTable {
    return this.ndTableFactory.combined(
      offers,
      NumberRange.fromStart(this.scrollPosition.startNights, this.tableSide).eachValue(),
      DateRange.fromStart(this.scrollPosition.startDate, this.tableSide).eachValue()
    );
  }

  private jumpToOffer(offer: ToursSearch.Offer): void {
    if (this.currentTableArea().isValuesInside(offer.dateRange.from, offer.nights)) {
      return;
    }

    if (this.ndLoadingMatrix.isLoaded(offer.dateRange.from, offer.nights)) {
      this.scrollPosition.center(offer.dateRange.from, offer.nights);
      this.createTable();
    }
  }

  private updateOffersIfNeeded(): void {
    const area = this.currentTableArea();

    if (this.ndLoadingMatrix.isEmptyArea(area)) {
      this.offersUploaderService.uploadOffers(area);
    }
  }

  private ensureScroll(offers: ToursSearch.Offer[]): void {
    if (!this.scrollPosition) {
      this.scrollPosition = new NightDateScrollPosition(offers, this.tableSide);
    }
  }
}
