import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BackOffice, Lang2, ServiceName } from '@temabit/package-types';

import { TransferHttpService } from '@services/transfer-http.service';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class BackOfficeEndpoint {
  private readonly basePath = `${environment.apiUrl}/${ServiceName.BackOffice}/site`;

  constructor(private http: TransferHttpService) { }

  getOffices(lang: Lang2): Observable<BackOffice.Actions.SiteGetOfficesResult> {
    return this.http.get<BackOffice.Actions.SiteGetOfficesResult>(`${this.basePath}/offices/${lang}`);
  }
}
