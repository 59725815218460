import { ToursSearch } from '@temabit/package-types';

import { TableFiller } from '@modules/tours/table-toolkit/classes/table-filler';

import { NightDateTable } from '../../interfaces/night-date-table';

export class NightDateTableFiller extends TableFiller<ToursSearch.Offer, Date, number> {
  constructor(table: NightDateTable) {
    super(table);
  }

  protected colHeaderValue(item: ToursSearch.Offer): Date {
    return  item.dateRange.from;
  }

  protected rowHeaderValue(item: ToursSearch.Offer): number {
    return  item.nights;
  }
}
