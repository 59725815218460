import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Currency, ToursCatalog, ToursSearch } from '@temabit/package-types';
import { Required } from '@temabit/package-utils';

import {
  buildFilter,
  extractDisplayFilterFromOffer,
  extractFlightsFromOffer,
  findFlightsRangeByFlightCodes,
  findOfferById
} from './helpers/offer-options.helper';
import { TableDrivenFilterStore } from '../available-offers/services/store/table-driven-filter.store';
import { FiltersStore } from './services/store/filters.store';
import { AvailableOffersStore } from './services/store/available-offers.store';
import { SearchResultStore } from '../../../dynamic-page-container/services/store/search-result.store';
import { QueryParamsService } from '@services/query-params.service';
import { SelectionStore } from './services/store/selection.store';
import { PriceDisplayOptionsStore } from './services/store/price-display-options.store';
import { InformationStore } from '@services/store/information.store';
import { OffersProcessor } from './classes/offers-processor';
import { HotelSearchService } from './services/hotel-search.service';

import { Range } from '@interfaces/range';
import { ChosenFlights } from './interfaces/offer-management';
import { SearchEventType, SearchResultEvent } from './interfaces/hotel-search';

import ResultStatus = ToursSearch.ResultStatus;

@UntilDestroy()
@Component({
  selector: 'app-hotel-availability',
  templateUrl: './hotel-availability.component.html',
  styleUrls: ['./hotel-availability.component.scss', './styles/option-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AvailableOffersStore,
    PriceDisplayOptionsStore,
    SelectionStore,
    FiltersStore,
    TableDrivenFilterStore,
    HotelSearchService
  ]
})
export class HotelAvailabilityComponent implements OnInit {
  @Required isLoading$!: Observable<boolean>;
  isOpen = false;

  constructor(
    public selectionStore: SelectionStore,
    public availableOffersStore: AvailableOffersStore,
    private filtersStore: FiltersStore,
    private searchResultStore: SearchResultStore,
    private queryParamsService: QueryParamsService,
    private tableDrivenFilterStore: TableDrivenFilterStore,
    private informationStore: InformationStore,
    private hotelSearch: HotelSearchService,
    private priceDisplayOptions: PriceDisplayOptionsStore
  ) { }

  toggleFilters(): void {
    this.isOpen = !this.isOpen;
  }

  ngOnInit(): void {
    this.isLoading$ = this.initLoading();
    this.selectionStore.init();
    this.searchResultStore.onChanges('hotels')
      .pipe(untilDestroyed(this))
      .subscribe((hotels) => {
        this.availableOffersStore.cleanOffers();
        this.updateStores(hotels);
      });

    this.hotelSearch.search$
      .pipe(
        filter((event): event is SearchResultEvent => event.type === SearchEventType.Result),
        untilDestroyed(this)
      )
      .subscribe(({ res: { result: { hotels } } }) => this.updateStores(hotels));
  }

  private updateStores(hotels: ToursSearch.HotelProposal[]): void {
    const targetHotel = this.extractHotel(hotels);
    if (!targetHotel) return;
    const processor = new OffersProcessor(targetHotel.offers);

    const unfiltered = processor
      .join(this.availableOffersStore.state.unfilteredOffers)
      .uniqBy('offerId')
      .sortOffersInAscendingOrderOfPrice()
      .get();

    const filter = buildFilter(unfiltered);

    const filtered = processor
      .filter(this.filtersStore.state.selectedValues)
      .get();

    if (this.isStoredUnfilteredOffersEmpty()) {
      this.initTableDrivenFilterAndSelections(filtered);
    }

    const currency = this.getOfferCurrencyProbe(unfiltered);
    if (currency) {
      this.priceDisplayOptions.setCurrencySwitch([{ value: currency }, { value: Currency.UAH }]);
    }

    this.availableOffersStore.update(unfiltered, filtered);
    this.filtersStore.patchAvailableValues(filter);
  }

  private getOfferCurrencyProbe(offers: ToursSearch.Offer[]): string | undefined {
    const offer = offers.find(o => o.currency);

    return offer?.currency;
  }

  private extractHotel(hotels: ToursSearch.HotelProposal[] = []): ToursSearch.HotelProposal | undefined {
    const { pageInfo } = this.informationStore.state;
    if (pageInfo?.type === ToursCatalog.PageType.Hotel) {
      return hotels.find(hotel => hotel.hotelId === pageInfo.hotel.hotelId);
    }

    return undefined;
  }

  private initLoading(): Observable<boolean> {
    return this.searchResultStore.state$.pipe(
      map(({ status }) => status === undefined || status === ResultStatus.Partial || status === ResultStatus.InProgress)
    );
  }

  private isStoredUnfilteredOffersEmpty(): boolean {
    return !this.availableOffersStore.state.unfilteredOffers.length;
  }

  private initTableDrivenFilterAndSelections(offers: ToursSearch.Offer[]): void {
    const { offerId, flightCodes } = this.queryParamsService.getParsedParamsSnapshot();

    const selectedOffer = this.findOfferByIdOrUseDefault(offers, offerId);
    const selectedFlights = this.findFlightsByCodesOrUseDefault(selectedOffer, flightCodes);
    const displayFilter = extractDisplayFilterFromOffer(selectedOffer);

    this.tableDrivenFilterStore.updateFilter(displayFilter);
    this.selectionStore.setSelectedOfferWithFlights(selectedOffer, selectedFlights);
  }

  private findFlightsByCodesOrUseDefault(offer: ToursSearch.Offer, codes?: Partial<Range<string>>): ChosenFlights {
    if (codes) {
      return findFlightsRangeByFlightCodes(offer, codes);
    }

    return extractFlightsFromOffer(offer);
  }

  private findOfferByIdOrUseDefault(offers: ToursSearch.Offer[], offerId?: string): ToursSearch.Offer {
    const [defaultOffer] = offers;

    return (offerId && findOfferById(offers, offerId)) || defaultOffer;
  }
}
