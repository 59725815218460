import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Lang2 } from '@temabit/package-types';

import { RawSeoLinkingLink } from '@interfaces/services/api/tours-catalog.endpoint';

@Pipe({
  name: 'seoLinkingText',
  pure: false
})
export class SeoLinkingTextPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(public translate: TranslateService, public cd: ChangeDetectorRef) { }

  transform(value: RawSeoLinkingLink['text']): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }
    if (typeof value === 'string') {
      return value;
    }

    return value[this.translate.currentLang as Lang2];
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }
}
