import { GenericObject } from '@temabit/package-types';

export class WebAppError extends Error {
  readonly data: GenericObject;

  constructor(message: string, data: GenericObject = { }) {
    super(message);
    this.name = this.constructor.name;
    this.data = data;
  }
}
