import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

@Component({
  selector: 'app-offers-grid',
  templateUrl: './offers-grid.component.html',
  styleUrls: ['./offers-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffersGridComponent {
  @Input() hotels: ToursSearch.HotelProposal[] = [];
  @Input() hotelsCount?: number;

  trackByFn(idx: number, item: ToursSearch.HotelProposal): number {
    return item.hotelId;
  }
}
