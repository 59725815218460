import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Lang2 } from '@temabit/package-types';

import { LanguageManagerService } from '@services/language-manager.service';
import { AppConfig, APP_CONFIG } from '../../../../../app-config.module';
import { onLangChange } from '@helpers/language.helper';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangSelectorComponent {
  public availableLanguages: Lang2[];
  public currentLangCode$ = onLangChange(this.translate).pipe(map(lang => this.langCodeTranslations[lang]));
  public langCodeTranslations = {
    [Lang2.Ru]: 'Languages.ShortCode.Rus',
    [Lang2.Uk]: 'Languages.ShortCode.Ukr'
  };

  constructor(
    private translate: TranslateService,
    private languageManager: LanguageManagerService,
    @Inject(APP_CONFIG) { languages: { primary, secondary } }: AppConfig
  ) {
    this.availableLanguages = [primary, secondary];
  }

  selectLanguage(lang: Lang2): void {
    this.languageManager.setLang(lang);
  }
}
