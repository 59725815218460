import { ChangeDetectionStrategy, Component } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ToursSearch } from '@temabit/package-types';

import { SearchResultStore } from '../../../dynamic-page-container/services/store/search-result.store';
import { SearchResultState } from '../../../dynamic-page-container/services/store/search-result.state';

import ResultStatus = ToursSearch.ResultStatus;

@UntilDestroy()
@Component({
  selector: 'app-tour-offers-content',
  templateUrl: './tour-offers-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourOffersContentComponent {
  hotels$: Observable<ToursSearch.HotelProposal[]> = this.store.onChanges('hotels');
  hasNext$: Observable<boolean> = this.store.onChanges('hasNext');
  isOffersLoading$ = this.store.state$
    .pipe(
      distinctUntilChanged((prev, next) => prev.page === next.page && prev.status === next.status),
      map(({ status, page }) => this.isLoadingStatus(status) && page === 1)
    );
  isNextPageLoading$ = this.store.state$
    .pipe(
      distinctUntilChanged((prev, next) => prev.page === next.page && prev.status === next.status),
      map(({ status, page }) => this.isLoadingStatus(status) && page != null && page > 1)
    );

  constructor(private store: SearchResultStore) { }

  loadNextPage(): void {
    this.store.loadNextPage();
  }

  private isLoadingStatus(status: SearchResultState['status']) {
    return status === undefined || status === ResultStatus.InProgress || status === ResultStatus.Partial;
  }
}
