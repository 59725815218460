import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursCatalog } from '@temabit/package-types';

import { MainPageBlockWithResizes } from '../../interfaces/main-page-block';

@Component({
  selector: 'app-tiles-grid',
  templateUrl: './tiles-grid.component.html',
  styleUrls: ['./tiles-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TilesGridComponent {
  public readonly MainPageBlockType = ToursCatalog.MainPageBlockType;
  public readonly BlockSize = ToursCatalog.BlockSize;
  @Input() blocks: MainPageBlockWithResizes[] = [];
}
