import isNull from 'lodash/isNull';
import { ParamMap, Params } from '@angular/router';

import { Otpusk, ToursSearch } from '@temabit/package-types';
import { cleanDeep } from '@temabit/package-utils';

import { sanitizeStringDate } from './date.helper';
import { toArrayOfNumbers, toFiniteNumber, toFiniteNumberString } from './parser.helper';
import { ShortUrlSearchParams as Param } from '@constants/short-url-search-params';

import { NormalizedSearch, SearchParams } from '@interfaces/modules/tours/search';
import { ParsedParams } from '@interfaces/parsed-params';

const separator: string = ',';

export function removeQueryParamsFromUrl(paramMap: ParamMap, keysToRemove: string[]): Params {
  const queryParams: Params = { };
  const keysToKeep = paramMap.keys.filter(k => !keysToRemove.includes(k));
  for (const key of keysToKeep) {
    queryParams[key] = paramMap.getAll(key);
  }

  return queryParams;
}

export function stringifyUrlParams(params: Record<string, string>): string {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
}

export function normalizeParams(params: ParsedParams): Params {
  return cleanDeep({
    searchId: params.searchId,
    offerId: params.offerId,
    codeFrom: params.flightCodes?.from,
    codeTo: params.flightCodes?.to,
    ...normalizeSearchParams(params.search ?? { })
  });
}

export function normalizeSearchParams(searchParams: SearchParams): NormalizedSearch {
  const destinationId = searchParams.destination?.id;
  const departureCity = searchParams.departureCity;
  const durationFrom = searchParams.duration?.from;
  const durationTo = searchParams.duration?.to;
  const adultsCount = searchParams.tourists?.adults;
  const ratingMin = searchParams.ratingMin;
  const ratingMax = searchParams.ratingMax;
  const priceMin = searchParams.price?.min;
  const priceMax = searchParams.price?.max;
  const hotels = searchParams.hotels ?? [];
  const resorts = searchParams.resorts ?? [];
  const childrenAges = (searchParams.tourists?.children ?? []).join(separator);
  const foods: string[] = searchParams.foods ?? [];
  const transports: string[] = searchParams.transports ?? [];
  const services: string[] = searchParams.services ?? [];
  const stars = searchParams.stars ?? [];

  return <NormalizedSearch> cleanDeep({
    [Param.DestinationId]: toFiniteNumberString(destinationId),
    [Param.DestinationType]: searchParams.destination?.type,
    [Param.Hotels]: join(hotels),
    [Param.Resorts]: join(resorts),
    [Param.DepartureCity]: toFiniteNumberString(departureCity),
    [Param.DatesCheckIn]: searchParams.dates?.checkIn,
    [Param.DatesCheckOut]: searchParams.dates?.checkOut,
    [Param.DurationFrom]: toFiniteNumberString(durationFrom),
    [Param.DurationTo]: toFiniteNumberString(durationTo),
    [Param.TouristsAdults]: toFiniteNumberString(adultsCount),
    [Param.TouristsChildren]: childrenAges,
    [Param.Foods]: join(foods),
    [Param.Transports]: join(transports),
    [Param.Services]: join(services),
    [Param.Stars]: join(stars),
    [Param.RatingMin]: toFiniteNumberString(ratingMin),
    [Param.RatingMax]: toFiniteNumberString(ratingMax),
    [Param.PriceMin]: toFiniteNumberString(priceMin),
    [Param.PriceMax]: toFiniteNumberString(priceMax)
  });
}

export function parseNormalizedParams(params: Partial<NormalizedSearch>): SearchParams {
  return <SearchParams> cleanDeep(
    {
      destination: parseDestination(params),
      hotels: toArrayOfNumbers(params[Param.Hotels], separator),
      resorts: toArrayOfNumbers(params[Param.Resorts], separator),
      departureCity: toFiniteNumber(params[Param.DepartureCity]),
      dates: parseDates(params),
      duration: parseDuration(params),
      tourists: parseTourists(params),
      foods: parseEnumArray<ToursSearch.Food>(params[Param.Foods], ToursSearch.Food),
      transports: parseEnumArray<ToursSearch.Transport>(params[Param.Transports], ToursSearch.Transport),
      services: parseEnumArray<Otpusk.MainService>(params[Param.Services], Otpusk.MainService),
      stars: parseEnumArray<ToursSearch.Stars>(params[Param.Stars], ToursSearch.Stars),
      ratingMin: toFiniteNumber(params[Param.RatingMin]),
      ratingMax: toFiniteNumber(params[Param.RatingMax]),
      price: parsePrice(params)
    },
    { emptyArrays: false }
  );
}

function join(value: any): string {
  return (isNull(value) ? [] : value).join(separator);
}

function parseDestination(params: Partial<NormalizedSearch>) {
  const type = params[Param.DestinationType];

  return {
    id: toFiniteNumber(params[Param.DestinationId]),
    type: type && parseEnumValue<ToursSearch.DestinationType>(type, ToursSearch.DestinationType)
  };
}

function parseDates(params: Partial<NormalizedSearch>) {
  const checkIn = params[Param.DatesCheckIn];
  const checkOut = params[Param.DatesCheckOut];

  return {
    checkIn: checkIn && sanitizeStringDate(checkIn),
    checkOut: checkOut && sanitizeStringDate(checkOut)
  };
}

function parseDuration(params: Partial<NormalizedSearch>) {
  return {
    from: Number(params[Param.DurationFrom]),
    to: Number(params[Param.DurationTo])
  };
}

function parseTourists(params: Partial<NormalizedSearch>) {
  const adults = toFiniteNumber(params[Param.TouristsAdults]);
  if (!adults) {
    return null;
  }

  return {
    adults,
    children: (params[Param.TouristsChildren] ?? '')
      .split(separator)
      .map(c => parseInt(c, 10))
      .filter(c => !Number.isNaN(c))
  };
}

function parsePrice(params: Partial<NormalizedSearch>) {
  return {
    min: toFiniteNumber(params[Param.PriceMin]),
    max: toFiniteNumber(params[Param.PriceMax])
  };
}

function parseEnumArray<E>(value?: string, enumObj?: object) {
  if (!value || !enumObj) {
    return [];
  }

  return value.split(separator)
    .map(val => parseEnumValue<E>(val, enumObj))
    .filter((val): val is E => val != null);
}

function parseEnumValue<E>(val: string | number, enumObj: object) {
  return Object.values(enumObj).includes(val) ? <E> <any> val : null;
}
