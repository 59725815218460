import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToursCatalog } from '@temabit/package-types';

import { HotelPageInfoStore } from '../../services/hotel-page-info.store';
import { PostReviewModalComponent } from '../post-review-modal/post-review-modal.component';

import { DisplayableHotelReview } from '@interfaces/hotel-review';
import { Hotel } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-hotel-reviews',
  templateUrl: './hotel-reviews.component.html',
  styleUrls: ['./hotel-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelReviewsComponent {
  @Input() hotel: Hotel | null = null;
  @Input() reviews: DisplayableHotelReview[] = [];
  isLoading$: Observable<boolean | undefined> = this.hotelPageInfo.onChanges('requests', ToursCatalog.Actions.Name.SiteGetHotelReviews, 'inProgress');
  lastPage$: Observable<boolean> = this.hotelPageInfo.onChanges('isLastReviewsPage');

  constructor(
    private readonly hotelPageInfo: HotelPageInfoStore,
    private readonly modalService: NgbModal,
    private readonly injector: Injector
  ) { }

  loadNextPage(): void {
    this.hotelPageInfo.loadNextPage();
  }

  trackByFn(idx: number, item: DisplayableHotelReview): string {
    return item.message.leftAt;
  }

  openPostReviewModal(): void {
    this.modalService.open(PostReviewModalComponent, { injector: this.injector, size: 'lg', centered: true });
  }
}
