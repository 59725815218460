import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { icon, latLng, Layer, Map, MapOptions, marker, tileLayer } from 'leaflet';

import { Required } from '@temabit/package-utils';
import { Location } from '@temabit/package-types';

import { HotelPopupComponent } from '@pages/tours/tour-offers-page/components/hotel-popup/hotel-popup.component';
import { getMarkerIconOptions } from '@constants/map-marker-icon';

@Component({
  selector: 'app-hotels-map',
  templateUrl: './hotels-map.component.html',
  styleUrls: ['./hotels-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelsMapComponent implements OnChanges {
  @Required options!: MapOptions;
  @Required layers!: Layer[];

  @Input() location?: Location;
  @Input() hotelName: string = '';
  @Input() priceUAH?: string;
  @Input() hotelUrl: string = '';
  @Input() imageLink?: string;

  onMapReady(map: Map): void {
    setTimeout(() => this.openPopup(map), 0);
  }

  ngOnChanges({ location }: SimpleChanges): void {
    if (location && location.previousValue !== location.currentValue) {
      this.initMap(location.currentValue);
    }
  }

  private initMap({ coordinates, zoom }: Location): void {
    this.options = {
      layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')],
      zoom: zoom || 15,
      center: latLng(...coordinates)
    };

    this.layers = [
      marker(coordinates, { title: this.hotelName, icon: icon(getMarkerIconOptions()) })
        .bindPopup(this.createPopup(), { minWidth: 400 })
    ];
  }

  private createPopup(): NgElement {
    const popupEl: NgElement & WithProperties<HotelPopupComponent> = document.createElement('app-hotel-popup') as any;
    popupEl.hotelUrl = this.hotelUrl;
    popupEl.priceUAH = this.priceUAH;
    popupEl.hotelName = this.hotelName;
    popupEl.imageLink = this.imageLink;

    return popupEl;
  }

  private openPopup(map: Map) {
    map.invalidateSize();
    this.layers[0]?.openPopup();
  }
}
