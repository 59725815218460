import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { uk } from 'date-fns/locale';
import { format as dateFormat } from 'date-fns';

import { Lang2 } from '@temabit/package-types';

import { pickDateLocale } from '@helpers/date.helper';

@Pipe({
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform, OnDestroy {
  private onLangChange?: Subscription;

  constructor(private translate: TranslateService, private cd: ChangeDetectorRef) { }

  transform(value: Date | undefined | null, format: string): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }
    if (!value) {
      return '-';
    }
    const locale = pickDateLocale(this.translate.currentLang as Lang2) ?? uk;

    return dateFormat(value, format, { locale });
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }
}
