import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Currency, UnknownCurrency } from '@temabit/package-types';

import { PriceDisplayOptionsStore } from '../../services/store/price-display-options.store';

import { PricePerNights } from '../../interfaces/price-per-nights';
import { PricePerTourists } from '../../interfaces/price-per-tourists';
import { DisplaySwitch, PriceDisplayOptions } from '../../interfaces/offer-management';

@Component({
  selector: 'app-price-display-options',
  template: '',
  styleUrls: ['./price-display-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDisplayOptionsComponent {
  selectedOptions$: Observable<PriceDisplayOptions> = this.store.onChanges('values');
  currencySwitch$: Observable<DisplaySwitch<Currency | UnknownCurrency>[]> = this.store.onChanges('switches', 'currency');
  pricePerTouristsSwitch$: Observable<DisplaySwitch<PricePerTourists>[]> = this.store.onChanges('switches', 'pricePerTourists');
  pricePerNightsSwitch$: Observable<DisplaySwitch<PricePerNights>[]> = this.store.onChanges('switches', 'pricePerNights');

  constructor(private store: PriceDisplayOptionsStore) { }

  setOptions(val: Partial<PriceDisplayOptions>): void {
    this.store.update(val);
  }
}
