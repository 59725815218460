import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ToursCatalog, Util } from '@temabit/package-types';

import { DynamicPage } from '@classes/decorators/dynamic-page.decorator';
import { SearchResultStore } from '../dynamic-page-container/services/store/search-result.store';
import { ErrorModalService } from '@modules/tours/core/services/error-modal.service';
import { SearchPageComponent } from '../dynamic-page-container/components/search-page/search-page.component';
import { ToursSearchService } from '@services/tours-search/tours-search.service';
import { InformationStore } from '@services/store/information.store';
import { pickCountryInfoOrResortInfo } from '@helpers/dynamic-page.helper';

@DynamicPage({
  pageName: ToursCatalog.PageName.Hotels,
  disableH1SmallUpdates: true
})
@Component({
  selector: 'app-tour-offers-page',
  templateUrl: './tour-offers-page.component.html',
  providers: [SearchResultStore, ToursSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourOffersPageComponent extends SearchPageComponent implements OnInit {
  private readonly htmlTextType: ToursCatalog.PageName.Main = ToursCatalog.PageName.Main;

  resortPageInfo$ = this.info.resortPageInfo$;
  page$: Observable<Util.Translation<ToursCatalog.HtmlPage>> = this.info.onChanges('pageInfo')
    .pipe(
      pickCountryInfoOrResortInfo(),
      map(info => info.type === ToursCatalog.PageType.Country ? info.country.pages : info.resort.pages),
      map(pages => pages[this.htmlTextType])
    );

  constructor(
    searchResult: SearchResultStore,
    errorModal: ErrorModalService,
    private readonly info: InformationStore
  ) {
    super(errorModal, searchResult);
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchResult.init();
  }
}
