import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToursSearch } from '@temabit/package-types';

import { SelectionState } from './selection.state';
import { QueryParamsService } from '@services/query-params.service';
import { AvailableOffersStore } from './available-offers.store';

import { ChosenFlights } from '../../interfaces/offer-management';

@UntilDestroy()
@Injectable()
export class SelectionStore extends Store<SelectionState> {
  constructor(
    private queryParamsService: QueryParamsService,
    private availableOffersStore: AvailableOffersStore
  ) {
    super(new SelectionState());
  }

  init(): void {
    this.availableOffersStore.onChanges('offers')
      .pipe(untilDestroyed(this))
      .subscribe((offers) => {
        if (this.state.selectedOffer && !this.isSelectedOfferActual(offers)) {
          this.clearSelections();
        }
      });
  }

  setSelectedOfferWithFlights(offer: ToursSearch.Offer, flights: ChosenFlights): void {
    this.queryParamsService.patchParams({
      offerId: offer.offerId,
      flightCodes: { from: flights.from?.code, to: flights.to?.code }
    });
    this.setState({ ...this.state, selectedOffer: offer, selectedFlights: flights });
  }

  setSelectedFlights(flights: ChosenFlights): void {
    this.queryParamsService.patchParams({
      flightCodes: { from: flights.from?.code, to: flights.to?.code }
    });
    this.setState({ ...this.state, selectedFlights: flights });
  }

  private clearSelections(): void {
    this.queryParamsService.patchParams({
      offerId: undefined,
      flightCodes: { from: undefined, to: undefined }
    });
    this.setState({
      ...this.state,
      selectedOffer: undefined,
      selectedFlights: { from: undefined, to: undefined }
    });
  }

  private isSelectedOfferActual(offers: ToursSearch.Offer[]): boolean {
    return offers.findIndex(o => o.offerId === this.state.selectedOffer?.offerId) !== -1;
  }
}

