import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ToursSearch } from '@temabit/package-types';

import { SearchResultStore } from '../../services/store/search-result.store';
import { ErrorModalService } from '@modules/tours/core/services/error-modal.service';
import { ErrorMessage } from '@constants/error-message.enum';

@Component({
  template: ''
})
export class SearchPageComponent implements OnInit, OnDestroy {
  private statusSubscription?: Subscription;

  constructor(
    protected readonly errorModalService: ErrorModalService,
    protected readonly searchResult: SearchResultStore
  ) { }

  init(): void {
    this.statusSubscription = this.searchResult.onChanges('status')
      .pipe(
        filter(status => status === ToursSearch.ResultStatus.Failed)
      )
      .subscribe(() => this.errorModalService.open(ErrorMessage.Default));
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.statusSubscription?.unsubscribe();
  }
}
