import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Util } from '@temabit/package-types';

import { SiteMenuItem } from '@constants/site-menu';
import { SiteMenuSection } from '@constants/site-menu-section';
import { AppConfig, APP_CONFIG } from '../../../../../app-config.module';

@Component({
  selector: 'app-header-nav-sm',
  templateUrl: './header-nav-sm.component.html',
  styleUrls: ['./header-nav-sm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*'
      })),
      state('closed', style({
        height: '0'
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class HeaderNavSmComponent {
  @Input() activeItem = SiteMenuSection.Tours;
  @Input() items: Readonly<SiteMenuItem<string | Util.Translation>[]> = [];
  @Input() isOpen = false;

  readonly phoneNumber: string;

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.phoneNumber = config.contactInfo.phoneNumbers.main;
  }
}
