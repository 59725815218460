import flatten from 'lodash/flatten';

import { Images } from '@temabit/package-types';

import { SiteReviewImage } from '@interfaces/hotel-review';

const PLACEHOLDER = 'assets/images/placeholder.png';

export interface GalleryLinks {
  small: string;
  medium: string;
  big: string;
}

export function pickGalleryImage(image: Images.ResizesLinks): GalleryLinks {
  const descSizeLinks = getImageLinksInDescOrder(image);

  let [big, medium, small] = descSizeLinks;
  if (descSizeLinks.length < 3) {
    if (!small) {
      small = medium;
    }
    if (!medium) {
      small = medium = big;
    }

    if (!big) {
      small = medium = big = PLACEHOLDER;
    }
  }

  return { small, medium, big };
}

export function getImageLinksInDescOrder(image: Images.ResizesLinks): string[] {
  return Object
    .entries(image)
    .sort(([size1], [size2]) => parseInt(size2, 10) - parseInt(size1, 10))
    .map(([size, link]) => link)
    .filter((link): link is string => Boolean(link));
}

export function collectImagesIds(entitiesWithImages: { images: SiteReviewImage[] }[]): string[] {
  const imageIdsPerEntity = entitiesWithImages.map(review => review.images.map(({ imageId }) => imageId));

  return flatten(imageIdsPerEntity)
    .filter((imageId): imageId is string => Boolean(imageId));
}
