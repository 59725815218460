import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ToursCatalog } from '@temabit/package-types';

import { BlocksStore } from './services/blocks.store';
import { StaticPage } from '@classes/decorators/static-page.decorator';

import { MainPageBlockWithResizes } from './interfaces/main-page-block';

@StaticPage({
  seoInfoUploadToken: ToursCatalog.StaticPageType.Main,
  layoutElementsVisibility: { searchForm: true }
})
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  providers: [BlocksStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPageComponent implements OnInit {
  @HostBinding('class') classNames = 'container';

  public readonly blocks: Observable<MainPageBlockWithResizes[]> = this.blocksStore.onChanges('pageBlocks');

  constructor(private readonly blocksStore: BlocksStore) { }

  ngOnInit(): void {
    this.blocksStore.loadPageBlocks();
  }
}
