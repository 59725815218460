import { ToursSearch } from '@temabit/package-types';

import { PricePerTourists } from '../../hotel-availability/interfaces/price-per-tourists';
import { PricePerNights } from '../../hotel-availability/interfaces/price-per-nights';
import { PriceDisplayOptions } from '../../hotel-availability/interfaces/offer-management';

export class PriceTransformer {
  private readonly transformerQueue = [this.selectPricePerNights.bind(this), this.selectPricePerTourists.bind(this)];

  constructor(
    private offer: ToursSearch.Offer,
    private basicPrice: number,
    private options: PriceDisplayOptions
  ) { }

  transform(): number {
    return this.transformerQueue.reduce((result, transformer) => transformer(this.offer, result), this.basicPrice);
  }

  private selectPricePerTourists(offer: ToursSearch.Offer, price: number): number {
    switch (this.options.pricePerTourists) {
      case PricePerTourists.PerTourist:
        return this.calculatePricePerTourist(offer.tourists, price);
      case PricePerTourists.Total:
      default:
        return price;
    }
  }

  private selectPricePerNights(offer: ToursSearch.Offer, price: number): number {
    switch (this.options.pricePerNights) {
      case PricePerNights.PerNight:
        return this.calculatePricePerNight(offer.nights, price);
      case PricePerNights.Total:
      default:
        return price;
    }
  }

  private calculatePricePerNight(nights: number, price: number): number {
    return Math.round(price / nights);
  }

  private calculatePricePerTourist(tourists: ToursSearch.OfferTourists, price: number): number {
    const adultsCount: number = tourists.adults;
    const childrenCount: number = tourists.childrenCount;
    const touristsCommonCount: number = adultsCount + childrenCount;

    return Math.round(price / touristsCommonCount);
  }
}
