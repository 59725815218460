import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Required } from '@temabit/package-utils';

import { RawBlockOffer } from '@interfaces/services/api/tours-catalog.endpoint';

@Component({
  selector: 'app-hotel-info',
  templateUrl: './hotel-info.component.html',
  styleUrls: ['./hotel-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelInfoComponent {
  @Input() theme!: 'white';
  @Input() @Required data!: RawBlockOffer;

  get hotel() {
    return this.data && this.data.hotel;
  }

  get offer() {
    return this.data;
  }

  calcPricePerTourist() {
    return this.data ? Number(this.offer.priceUAH) / this.offer.tourists.adultsCount : 0;
  }
}
