import { Pipe, PipeTransform } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

import { searchSelectorsTranslations } from '@constants/translation-dictionaries';

import { Dictionary } from '@interfaces/modules/tours/search';

@Pipe({
  name: 'transportTranslationPath'
})
export class TransportTranslationPathPipe implements PipeTransform {
  private readonly searchSelectorsTranslations: Dictionary = searchSelectorsTranslations;

  transform(code?: ToursSearch.Transport): string {
    return code ? `${this.searchSelectorsTranslations.transport}.${code}` : '';
  }
}
