import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Util } from '@temabit/package-types';

import { SiteMenuSection } from '@constants/site-menu-section';
import { SiteMenuItem } from '@constants/site-menu';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavComponent {
  @Input() activeItem = SiteMenuSection.Tours;
  @Input() items: Readonly<SiteMenuItem<string | Util.Translation>[]> = [];
}
