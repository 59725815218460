import { Input, OnInit } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToursSearch } from '@temabit/package-types';
import { Required } from '@temabit/package-utils';

import { SelectionStore } from '../../hotel-availability/services/store/selection.store';
import { HotelSearchService } from '../../hotel-availability/services/hotel-search.service';

import { CellColor } from '@modules/tours/table-toolkit/interfaces/cell-color';
import { NightDateCell } from '../interfaces/night-date-table';
import { CellLayout } from '../interfaces/cell-layout.enum';

export abstract class CellBase implements OnInit {
  @Required type$!: Observable<CellColor | undefined>;
  @Required layout$!: Observable<CellLayout>;

  @Required protected cellValue!: NightDateCell;
  protected chooseType$: Subject<void> = new Subject<void>();

  @Required private cell$!: Observable<any>;

  protected constructor(
    protected selectionStore: SelectionStore,
    protected searchService: HotelSearchService
  ) { }

  @Input()
  set cell(c) {
    this.cellValue = c;
    this.chooseType$.next();
  }

  get cell() {
    return this.cellValue;
  }

  get offer(): ToursSearch.Offer | null {
    return this.cellValue.data ?? null;
  }

  abstract isUnfiltered(): boolean;

  abstract isSelectedCell(): boolean;

  protected abstract getLayout(offer: ToursSearch.Offer | null): CellLayout;

  protected chooseType(): CellColor | undefined {
    if (this.isSelectedFilter()) {
      return CellColor.Marked;
    }

    if (this.isSelectedOffer()) {
      return CellColor.Selected;
    }

    if (this.offer && this.isUnfiltered()) {
      return CellColor.Secondary;
    }

    return undefined;
  }

  isSelectedOffer(): boolean {
    const selectedOfferId = this.selectionStore.state?.selectedOffer?.offerId;
    const currentOfferId = this.offer?.offerId;

    return selectedOfferId != null && selectedOfferId === currentOfferId;
  }

  isSelectedFilter(): boolean {
    if (this.offer) {
      return this.isSelectedCell() && !this.isSelectedOffer();
    }

    return false;
  }

  ngOnInit(): void {
    this.cell$ = merge(
      this.chooseType$.asObservable(),
      this.searchService.search$,
      this.selectionStore.onChanges('selectedOffer')
    );

    this.type$ = this.cell$.pipe(
      map(() => this.chooseType())
    );

    this.layout$ = this.cell$.pipe(
      map(() => this.getLayout(this.offer))
    );
  }
}
