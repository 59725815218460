import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import merge from 'lodash/merge';
import { ActivatedRoute, Router } from '@angular/router';

import { Lang2 } from '@temabit/package-types';

import { ToursSearchEndpoint } from '../api/tours-search.endpoint';
import { transformLinkWithCurrentLang } from '@helpers/language.helper';
import { normalizeSearchParams } from '@helpers/url-params.helper';

import { SearchParams } from '@interfaces/modules/tours/search';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  constructor(
    private toursSearch: ToursSearchEndpoint,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  navigateToSearchPage(params: SearchParams) {
    const page = 1;
    const lang = this.translate.currentLang as Lang2;

    return this.toursSearch.search(params, lang, page)
      .pipe(
        tap(({ url, searchId }) => this.navigateToFoundPage(url, lang, searchId, params))
      );
  }

  private navigateToFoundPage(url: string, lang: Lang2, searchId: string, search: SearchParams): void {
    this.router.navigate([transformLinkWithCurrentLang(url, lang)], {
      relativeTo: this.route,
      queryParams: merge({ searchId }, normalizeSearchParams(search)),
    }).then();
  }
}
