import { UrlMatcher, UrlSegment } from '@angular/router';

import { Lang2 } from '@temabit/package-types';

import { LanguagesConfig } from '../app-config.module';

function isMainPageWithPrefix(segments: UrlSegment[], prefix: Lang2) {
  return segments.length === 1 && segments[0].path === prefix;
}

function isAnotherPageWithPrefix(segments: UrlSegment[], prefix: Lang2) {
  return segments.length > 1 && segments[0].path === prefix;
}

export function languageMatcher(config: LanguagesConfig): UrlMatcher {
  return (segments) => {
    if (isMainPageWithPrefix(segments, config.secondary)) {
      return { consumed: [segments[0]] };
    }

    if (isAnotherPageWithPrefix(segments, config.primary)) {
      return { consumed: [segments[0]] };
    }

    return { consumed: [] };
  };
}

export function extractUrlElements(url: string): string[] {
  return url
    .split('#')[0]
    .split('?')[0]
    .split('/')
    .filter(item => Boolean(item));
}
