import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { Lang2 } from '@temabit/package-types';

import { config } from '../../app-config.module';
import { DesktopLayoutComponent } from './desktop-layout.component';
import { languageMatcher } from '@helpers/url-matcher.helper';
import { MainPageComponent } from '@pages/tours/main-page/main-page.component';
import { PageResolverService } from '@pages/tours/dynamic-page-container/services/page-resolver.service';
import { DynamicPageDeallocationGuard } from '@pages/tours/dynamic-page-container/guards/dynamic-page-deallocation.guard';
import { DynamicPageContainerComponent } from '@pages/tours/dynamic-page-container/dynamic-page-container.component';

const moduleLoaders = {
  NotFound: () => import('@pages/not-found/not-found.module').then(mod => mod.NotFoundModule),
  OfferMeTourPage: () => import('@pages/tours/offer-me-tour-page/offer-me-tour-page.module').then(mod => mod.OfferMeTourPageModule),
  ReviewsPage: () => import('@pages/tours/reviews-page/reviews-page.module').then(mod => mod.ReviewsPageModule),
  CountriesPage: () => import('@pages/tours/countries-page/countries-page.module').then(mod => mod.CountriesPageModule),
  HotelsCatalogPage: () => import('@pages/tours/hotels-catalog-page/hotels-catalog-page.module').then(mod => mod.HotelsCatalogPageModule),
  AboutCompanyPage: () => import('@pages/tours/about-company-page/about-company-page.module').then(mod => mod.AboutCompanyPageModule),
  VacanciesPage: () => import('@pages/tours/vacancies-page/vacancies-page.module').then(mod => mod.VacanciesPageModule),
  TouristDictionaryPage: () => import('@pages/tours/tourist-dictionary-page/tourist-dictionary-page.module')
    .then(mod => mod.TouristDictionaryPageModule),
  CorporateServicesPage: () => import('@pages/corporate-services-page/corporate-services-page.module')
    .then(mod => mod.CorporateServicesPageModule),
  MassMediaAboutUsPage: () => import('@pages/tours/mass-media-about-us-page/mass-media-about-us-page.module')
    .then(mod => mod.MassMediaAboutUsPageModule),
  LegalInfoPage: () => import('@pages/tours/legal-information-page/legal-information-page-routing.module')
    .then(mod => mod.LegalInformationPageRoutingModule),
  OfficesPage: () => import('@pages/offices-page/offices-page.module').then(mod => mod.OfficesPageModule),
  StaticPage: () => import('@pages/tours/static-page/static-page.module').then(mod => mod.StaticPageModule),
  TourAgreementPage: () => import('@pages/tour-agreement-page/tour-agreement-page.module').then(mod => mod.TourAgreementPageModule),
  OnlineCheckout: () => import('@pages/online-checkout-page/online-checkout-page.module').then(mod => mod.OnlineCheckoutPageModule),
};

export const routes: Routes = [
  { path: 'page-not-found', pathMatch: 'full', loadChildren: moduleLoaders.NotFound },
  { path: `${Lang2.Uk}/page-not-found`, pathMatch: 'full', loadChildren: moduleLoaders.NotFound },
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [
      {
        matcher: languageMatcher(config.languages),
        children: [
          { path: 'offer-me-tour', loadChildren: moduleLoaders.OfferMeTourPage },
          { path: 'reviews', loadChildren: moduleLoaders.ReviewsPage },
          { path: 'countries', loadChildren: moduleLoaders.CountriesPage },
          { path: 'hotels', loadChildren: moduleLoaders.HotelsCatalogPage },
          { path: 'about', loadChildren: moduleLoaders.AboutCompanyPage },
          { path: 'vakansii', loadChildren: moduleLoaders.VacanciesPage },
          { path: 'slovar-turista', loadChildren: moduleLoaders.TouristDictionaryPage },
          { path: 'business', loadChildren: moduleLoaders.CorporateServicesPage },
          { path: 'smi', loadChildren: moduleLoaders.MassMediaAboutUsPage },
          { path: 'legal-info', loadChildren: moduleLoaders.LegalInfoPage },
          { path: 'offices', loadChildren: moduleLoaders.OfficesPage },
          { path: 'page', loadChildren: moduleLoaders.StaticPage },
          { path: 'tour-agreement/:orderId', loadChildren: moduleLoaders.TourAgreementPage },
          { path: 'checkout/:orderId', loadChildren: moduleLoaders.OnlineCheckout },
          { path: '', component: MainPageComponent },
        ]
      },
      {
        path: '**',
        resolve: { page: PageResolverService },
        canDeactivate: [DynamicPageDeallocationGuard],
        component: DynamicPageContainerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DesktopLayoutRoutingModule { }
