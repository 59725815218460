import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Lang2, ToursSearch } from '@temabit/package-types';
import { Required } from '@temabit/package-utils';

import { convertRawSuggestion } from '@helpers/converter.helper';
import { ToursSearchEndpoint } from '@services/api/tours-search.endpoint';
import { valueAccessor } from '@helpers/angular.helper';
import { onLangChange } from '@helpers/language.helper';
import { PlatformService } from '@services/platform.service';

import { Destination } from '@interfaces/modules/tours/search';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  providers: [valueAccessor(DestinationComponent)]
})
export class DestinationComponent implements OnInit, ControlValueAccessor {
  @Required destinations$!: Observable<Destination[]>;
  public autocomplete$ = new BehaviorSubject<string>('');
  public isLoading = false;

  @Input() types: ToursSearch.DestinationType[] = [];
  @Input() preloadDefaultSuggestions = true;
  @Input() placeholder = '';
  @Input() typeToSearchText = '';

  @Output() selected = new EventEmitter<Destination>();

  private destinationValue?: Destination;

  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  constructor(
    private toursSearch: ToursSearchEndpoint,
    private translate: TranslateService,
    private errorHandler: ErrorHandler,
    private platformService: PlatformService,
  ) { }

  groupByDestinationType(destination: Destination): ToursSearch.DestinationType {
    return destination.type;
  }

  get destination() {
    return this.destinationValue;
  }

  set destination(destination) {
    this.selected.next(cloneDeep(destination));
    this.destinationValue = cloneDeep(destination);
    this.onTouched();
    this.onChange(cloneDeep(destination));
  }

  comparator(option: Destination, selected: Destination): boolean {
    return option.id === selected.id;
  }

  ngOnInit(): void {
    this.destinations$ = combineLatest([
      onLangChange(this.translate),
      this.autocomplete$
        .pipe(
          distinctUntilChanged(),
          filter(isString),
          map(term => term.trim()),
        )
    ])
      .pipe(
        this.platformService.accessForBrowser(),
        debounceTime(500),
        tap(() => this.isLoading = true),
        switchMap(
          ([lang, term]) => this.getDestinations(lang, term)
            .pipe(catchError((err) => {
              if (err) {
                this.errorHandler.handleError(err);
              }

              return of([]);
            }))
        ),
        tap(() => this.isLoading = false)
      );
  }

  writeValue(destination?: Destination): void {
    this.destinationValue = destination && cloneDeep(destination);
    this.autocomplete$.next(destination?.id.toString() ?? '');
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  private getDestinations(lang: Lang2, term: string): Observable<Destination[]> {
    if (!this.preloadDefaultSuggestions && term === '') {
      return of([]);
    }

    return this.toursSearch.getSuggestions(lang, term, term === '', this.types)
      .pipe(
        map(suggestions => suggestions.map(convertRawSuggestion)),
        map(destinations => destinations.sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0)))
      );
  }
}
