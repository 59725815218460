import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, of } from 'rxjs';

import { Images } from '@temabit/package-types';

import { BlocksState } from './blocks.state';
import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { ImagesEndpoint } from '@services/api/images.endpoint';
import { NotificationService } from '@services/notification.service';
import { ErrorMessage } from '@constants/error-message.enum';

import { RawSiteMainPageBlock } from '@interfaces/services/api/tours-catalog.endpoint';
import { MainPageBlockWithResizes } from '../interfaces/main-page-block';

@UntilDestroy()
@Injectable()
export class BlocksStore extends Store<BlocksState> {
  constructor(
    private toursCatalogEndpoint: ToursCatalogEndpoint,
    private imagesEndpoint: ImagesEndpoint,
    private errorNotification: NotificationService
  ) {
    super(new BlocksState());
  }

  loadPageBlocks(): void {
    this.toursCatalogEndpoint
      .getMainPageBlocks()
      .pipe(
        switchMap(({ blocks }) => forkJoin({
          blocks: of(blocks),
          imageResizes: this.imagesEndpoint
            .getCustomImagesLinks(blocks.map(({ _id }) => _id))
            .pipe(
              map(({ imageResizesLinks }) => imageResizesLinks),
              catchError(() => {
                this.errorNotification.error(ErrorMessage.ImageLoadingFailure);

                return of({ });
              })
            )
        })),
        map(({ blocks, imageResizes }) => this.enrichBlocksByResizes(blocks, imageResizes)),
        untilDestroyed(this)
      ).subscribe(pageBlocks => this.setState({ ...this.state, pageBlocks }));
  }

  private enrichBlocksByResizes(blocks: RawSiteMainPageBlock[], resizes: Images.ImageResizesLinks): MainPageBlockWithResizes[] {
    return blocks.map((block) => {
      const { imageId, imageIds } = block;
      if (imageId) {
        return { ...block, imageResizes: { [imageId]: resizes[imageId] } };
      }

      if (imageIds) {
        const { ru, uk } = imageIds;
        const result = { ...block, imageResizes: { } as Images.ImageResizesLinks };
        if (ru) {
          result.imageResizes[ru] = resizes[ru];
        }
        if (uk) {
          result.imageResizes[uk] = resizes[uk];
        }

        return result;
      }

      return block;
    });
  }
}
