import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';

import { ToursSearch } from '@temabit/package-types';

import { SelectionStore } from '../../../hotel-availability/services/store/selection.store';
import { RoomFoodTableFiller } from '../../classes/room-food-table/room-food-table-filler';
import { sortOffersInAscendingOrderOfPrice } from '../../helpers/offers.helper';
import { fillEmptyCellsByUnfilteredOffers } from '@modules/tours/table-toolkit/helpers/table.helper';
import { EmptyTableBuilder } from '@modules/tours/table-toolkit/classes/empty-table-builder';
import { TableDrivenFilterStore } from '../../services/store/table-driven-filter.store';
import { AvailableOffersStore } from '../../../hotel-availability/services/store/available-offers.store';
import { extractDisplayFilterFromOffer, extractFlightsFromOffer } from '../../../hotel-availability/helpers/offer-options.helper';
import { buildFoodLabels, buildRoomLabels, filterOffersByNightDate } from '../../helpers/room-food-helper';

import { TableDrivenFilter } from '../../interfaces/table-driven-filter';
import { RoomFoodCell, RoomFoodTable } from '../../interfaces/room-food-table';

@Component({
  selector: 'app-room-food-table',
  templateUrl: './room-food-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomFoodTableComponent implements OnInit {
  table$!: Observable<RoomFoodTable>;

  constructor(
    private selectionStore: SelectionStore,
    private availableOffersStore: AvailableOffersStore,
    private filterStore: TableDrivenFilterStore
  ) { }

  ngOnInit(): void {
    this.table$ = combineLatest([
      this.filterStore.state$,
      this.availableOffersStore.onChanges('offers') as Observable<ToursSearch.Offer[]>
    ]).pipe(
      filter(([_, offers]) => offers.length > 0),
      map(([filter, offers]) => this.buildTable(offers, filter))
    );
  }

  cellClicked(cell: RoomFoodCell): void {
    if (cell.data) {
      this.selectOffer(cell.data);
    }
  }

  selectOffer(o: ToursSearch.Offer): void {
    this.filterStore.updateFilter(extractDisplayFilterFromOffer(o));
    this.selectionStore.setSelectedOfferWithFlights(o, extractFlightsFromOffer(o));
  }

  private buildTable(offers: ToursSearch.Offer[], filter: TableDrivenFilter): RoomFoodTable {
    const sorted = sortOffersInAscendingOrderOfPrice(offers);
    const relevant = filterOffersByNightDate(sorted, filter);

    const colLabels = buildFoodLabels(sorted);
    const rowLabels = buildRoomLabels(sorted);

    const emptyTable = new EmptyTableBuilder<ToursSearch.Offer, ToursSearch.Food, string>(rowLabels, colLabels).build();
    const unfilteredLayer = new RoomFoodTableFiller(cloneDeep(emptyTable)).fill(sorted);
    const relevantLayer = new RoomFoodTableFiller(cloneDeep(emptyTable)).fill(relevant);

    return fillEmptyCellsByUnfilteredOffers(unfilteredLayer, relevantLayer);
  }
}
