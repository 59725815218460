import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Lang2 } from '@temabit/package-types';

import { pickDateLocale } from '@helpers/date.helper';

import { NgbDateRange } from '@interfaces/modules/tours/search';

@Pipe({
  name: 'tourDateRange',
  pure: false
})
export class DateRangePipe implements PipeTransform {
  private adapter: NgbDateNativeAdapter = new NgbDateNativeAdapter();

  constructor(private translate: TranslateService) { }

  transform(dateRange?: NgbDateRange): string {
    if (!dateRange) {
      return '';
    }

    const currentLang = this.translate.currentLang as Lang2;

    return `${this.buildDate(currentLang, dateRange.from)} - ${this.buildDate(currentLang, dateRange.to)}`;
  }

  private buildDate(lang: Lang2, ngbDate?: NgbDateStruct): string {
    if (!ngbDate) {
      return '';
    }

    const locale: Locale = pickDateLocale(lang) || uk;
    const date = this.adapter.toModel(ngbDate);
    if (!date) {
      return '';
    }

    return format(date, 'dd MMM EEEEEE', { locale });
  }
}
