import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Lang2 } from '@temabit/package-types';

import dateTranslations from '@constants/date-translations';

@Injectable()
export class CustomNgbDatepickerI18n extends NgbDatepickerI18n {
  constructor(private translate: TranslateService) {
    super();
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }

  getMonthFullName(month: number, year?: number): string {
    return dateTranslations[this.translate.currentLang as Lang2].month[month - 1];
  }

  getMonthShortName(month: number, year?: number): string {
    return '';
  }

  getWeekdayShortName(weekday: number): string {
    return dateTranslations[this.translate.currentLang as Lang2].weekday[weekday - 1];
  }
}
