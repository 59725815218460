import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { Required } from '@temabit/package-utils';

import { OptionLabelDirective } from '../../directives/option-label.directive';

import { DisplaySwitch } from '../../interfaces/offer-management';

@Component({
  selector: 'app-display-option-group',
  templateUrl: './display-option-group.component.html',
  styleUrls: ['./display-option-group.component.scss', '../../styles/option-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayOptionGroupComponent<T> {
  @Required @Input() selection!: T;
  @Input() labelWidth = '';
  @Input() options: DisplaySwitch<T>[] = [];

  @Output() selectionChange = new EventEmitter<T>();

  @ContentChild(OptionLabelDirective, { read: TemplateRef, static: true }) label!: TemplateRef<any>;
}
