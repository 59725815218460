import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Lang2, ToursCatalog } from '@temabit/package-types';

import { buildPageDecorator, extractHeadTags } from './helpers';
import { createDefaultSearchWithDestination } from '@helpers/search.helper';

import { DynamicPageDecorator, DynamicPageOptions, InjectedServices, LayoutElementsVisibility } from './interfaces';
import { PageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

function setSeoInfo(pageName: ToursCatalog.PageName, services: InjectedServices, options: DynamicPageOptions) {
  const { globalLayout, translate, info, seoService } = services;
  const pageInfo = info.state.pageInfo;
  if (!pageInfo) {
    return;
  }
  const seoInfo = extractHeadTags(pageName, translate.currentLang as Lang2, pageInfo);
  globalLayout.changeBackgroundImage(seoInfo.img?.src);
  seoService.patchPageTags(seoInfo, { skipH1Update: options.disableH1SmallUpdates });
}

function applyDefaultSearchParams({ searchForm, info }: InjectedServices): void {
  const search = createDefaultSearchWithDestination(info.state.pageInfo);
  searchForm.setSearchParams(search);
}

function getDefaultDynamicPageLayout(): Required<LayoutElementsVisibility> {
  return {
    thematicSectionsMenu: true,
    searchForm: true,
    breadcrumbs: true
  };
}

// tslint:disable-next-line:variable-name
export const DynamicPage: DynamicPageDecorator = (options) => {
  const { pageName, setDefaultSearchParams = false } = options;
  let pageInfoSubscription: Subscription;

  return buildPageDecorator({
    onInitMixin(services) {
      pageInfoSubscription = services.info.onChanges('pageInfo')
        .pipe(
          filter((info): info is PageInfo => info !== undefined)
        )
        .subscribe((info) => {
          services.globalLayout.setBreadcrumbs(info.breadcrumbs);
          services.globalLayout.setThematicSectionsMenu(info.menu);
        });
      setSeoInfo(pageName, services, options);
      services.globalLayout.setElementsVisibility(getDefaultDynamicPageLayout());
      setDefaultSearchParams && applyDefaultSearchParams(services);
    },
    onDestroyMixin({ seoService }) {
      seoService.reset();
      pageInfoSubscription.unsubscribe();
    },
    onLangChange(services) {
      setSeoInfo(pageName, services, options);
    }
  });
};
