import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ToursSearch } from '@temabit/package-types';

import { SelectionStore } from '../hotel-availability/services/store/selection.store';
import { NightDateTableFactoryService } from './services/night-date-table-factory.service';
import { LoadingMatrixStore } from './services/store/loading-matrix.store';
import { OffersUploaderService } from './services/offers-uploader.service';
import { TableDrivenFilterStore } from './services/store/table-driven-filter.store';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_NIGHT_DATE_TABLE_SIDE,
  NightDateStore,
  XS_DATE_FORMAT,
  XS_NIGHT_DATE_TABLE_SIDE
} from './services/store/night-date.store';
import { DateRange } from '@classes/range/date-range';
import { NumberRange } from '@classes/range/number-range';
import { Breakpoint, ResponsiveService } from '@services/responsive.service';

import { ChosenFlights } from '../hotel-availability/interfaces/offer-management';
import { ScrollDirection } from '@modules/tours/table-toolkit/interfaces/scroll';

@UntilDestroy()
@Component({
  selector: 'app-available-offers',
  templateUrl: './available-offers.component.html',
  styleUrls: ['./available-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    NightDateTableFactoryService,
    LoadingMatrixStore,
    OffersUploaderService,
    NightDateStore
  ]
})
export class AvailableOffersComponent implements OnInit {
  selectedFilter$ = this.tableDrivenFilterStore.state$;
  selectedFlights$: Observable<ChosenFlights> = this.selectionStore.onChanges('selectedFlights');
  selectedOffer$: Observable<ToursSearch.Offer | undefined> = this.selectionStore.onChanges('selectedOffer');
  datesRange$ = this.nightDateStore.onChanges('table', 'colHeader')
    .pipe(map(header => DateRange.fromArray(header.getSegments())));
  nightsRange$ = this.nightDateStore.onChanges('table', 'rowHeader')
    .pipe(map(header => NumberRange.fromArray(header.getSegments())));
  format = DEFAULT_DATE_FORMAT;

  constructor(
    private readonly selectionStore: SelectionStore,
    private readonly tableDrivenFilterStore: TableDrivenFilterStore,
    private readonly nightDateStore: NightDateStore,
    private readonly responsive: ResponsiveService,
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.nightDateStore.init();
    this.responsive.windowSize$
      .pipe(untilDestroyed(this))
      .subscribe((breakpoint) => {
        this.updateNightDateColumnsCount(breakpoint);
        this.updateNightDateHeaderFormat(breakpoint);
        this.cd.markForCheck();
      });
  }

  scroll(direction: ScrollDirection): void {
    this.nightDateStore.scroll(direction);
  }

  private updateNightDateColumnsCount(breakpoint: Breakpoint): void {
    const colsCount = breakpoint === 'xs' ? XS_NIGHT_DATE_TABLE_SIDE : DEFAULT_NIGHT_DATE_TABLE_SIDE;
    this.nightDateStore.changeTableSide(colsCount);
  }

  private updateNightDateHeaderFormat(breakpoint: Breakpoint): void {
    this.format = breakpoint === 'xs' ? XS_DATE_FORMAT : DEFAULT_DATE_FORMAT;
  }
}
