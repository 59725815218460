import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { Subscription } from 'rxjs';

import { Lang2 } from '@temabit/package-types';

import { pickDateLocale } from '@helpers/date.helper';

@Pipe({
  name: 'hotelReviewTourRange',
  pure: false
})
export class HotelReviewTourRangePipe implements PipeTransform, OnDestroy {
  private readonly format = 'EEEEEE dd MMMM, y';
  private onLangChange?: Subscription;

  constructor(
    private readonly translate: TranslateService,
    private readonly cd: ChangeDetectorRef
  ) { }

  transform({ checkIn, checkOut }: { checkIn: string, checkOut?: string }): string {
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }

    const currentLang = this.translate.currentLang as Lang2;
    const locale = pickDateLocale(currentLang) ?? uk;

    const formattedCheckIn = format(new Date(checkIn), this.format, { locale });
    const formattedCheckOut = checkOut && format(new Date(checkOut), this.format, { locale });

    return [formattedCheckIn, formattedCheckOut].join(' - ');
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onLangChange = undefined;
  }
}
