import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { cleanDeep } from '@temabit/package-utils';

import { ToursCatalogEndpoint } from '@services/api/tours-catalog.endpoint';
import { ApiLoader } from '@classes/api-loader';
import { ErrorMessage } from '@constants/error-message.enum';
import { SuccessMessage } from '@constants/success-message.enum';
import { NotificationService } from '@services/notification.service';

import { NewSiteFeedback } from '@interfaces/services/api/tours-catalog.endpoint';

@UntilDestroy()
@Component({
  selector: 'app-send-site-feedback-modal',
  templateUrl: './send-site-feedback-modal.component.html',
  styleUrls: ['./send-site-feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendSiteFeedbackModalComponent implements OnInit {
  @HostBinding('class') readonly hostClasses = 'container p-3';

  readonly isLoading = false;
  readonly feedbackForm = this.fb.group(
    {
      name: [null, [Validators.required, Validators.maxLength(100)]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, Validators.pattern(/380\d{9}/)],
      text: [null, Validators.required]
    },
    { updateOn: 'blur' }
  );
  readonly isLoading$: Observable<boolean>;

  private readonly siteFeedbackLoader = new ApiLoader<NewSiteFeedback>();

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly toursCatalog: ToursCatalogEndpoint,
    private readonly notification: NotificationService
  ) {
    this.isLoading$ = this.siteFeedbackLoader.status$.pipe(
      map(val => val === 'inProgress')
    );
  }

  ngOnInit(): void {
    this.siteFeedbackLoader.data$
      .pipe(
        switchMap(data => this.toursCatalog.createSiteFeedback(data.params).pipe(
          this.siteFeedbackLoader.completion(data)
        )),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (data.error) {
          this.notification.error(ErrorMessage.SiteFeedbackPostingFailed);
        } else {
          this.notification.success(SuccessMessage.SiteFeedbackPosted);
          this.modal.close();
        }
      });
  }

  submit() {
    const feedback = cleanDeep(this.feedbackForm.value) as NewSiteFeedback;
    this.siteFeedbackLoader.fetch(feedback, { });
  }
}
