import { ToursSearch } from '@temabit/package-types';
import { Required } from '@temabit/package-utils';

import { generateRequestState } from '@helpers/endpoint.helper';

import { SearchParams } from '@interfaces/modules/tours/search';

export class SearchResultState {
  @Required actualSearch!: { search: SearchParams, searchId: string | undefined };
  hotels: ToursSearch.HotelProposal[] = [];
  page = 0;
  hasNext = true;
  status?: ToursSearch.ResultStatus;
  requests = {
    search: generateRequestState()
  };
}
