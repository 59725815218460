import { Pipe, PipeTransform } from '@angular/core';

import { Images } from '@temabit/package-types';

interface Size {
  width: number;
  height: number;
}

interface ImageLink {
  size: Size;
  resizeMarker: Images.Resizes;
  src: string;
}

@Pipe({
  name: 'srcSet'
})
export class SrcSetPipe implements PipeTransform {
  private readonly RESIZE_PATTERN = /^(\d+)x(\d+)$/;
  private readonly PLACEHOLDER = 'assets/images/placeholder.png';

  transform(links?: Images.ResizesLinks): string {
    if (!links) {
      return this.PLACEHOLDER;
    }
    const [x2, x1] = this.determineImageSizes(links).sort((a, b) => b.size.width - a.size.width);

    return x2 ? this.toSrcSet((x1 ?? x2).src, x2.src) : this.PLACEHOLDER;
  }

  private determineImageSizes(links: Images.ResizesLinks): ImageLink[] {
    return Object
      .entries(links)
      .filter((entry): entry is [Images.Resizes, string] => this.isValidResizeEntry(entry))
      .map(([size, link]) => ({
        resizeMarker: size,
        src: link,
        size: this.parseSize(size)
      }));
  }

  private parseSize(size: string): Size {
    const [_, width, height] = this.RESIZE_PATTERN.exec(size) ?? [];
    if (!width || !height) {
      throw new Error(`Unsupported size string - ${size}`);
    }

    return { width: Number.parseInt(width, 10), height: Number.parseInt(height, 10) };
  }

  private isValidResizeEntry(entry: [string, string | undefined]): entry is [Images.Resizes, string] {
    return this.RESIZE_PATTERN.test(entry[0]) && Boolean(entry[1]);
  }

  private toSrcSet(x1: string, x2: string): string {
    return `${x1}, ${x2} 2x`;
  }
}
