import io from 'socket.io-client';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_URL } from 'package-angular';
import { Required } from '@temabit/package-utils';

import { SearchResultStatus } from '@interfaces/tours-search-result';

@Injectable({
  providedIn: 'root'
})
export class SocketIOService {
  @Required public connect$!: Observable<void>;
  @Required public disconnect$!: Observable<void>;
  @Required public searchResultStatus$!: Observable<SearchResultStatus>;

  private readonly socket: SocketIOClient.Socket;

  constructor(@Inject(API_URL) apiUrl: string) {
    this.socket = io(apiUrl, { autoConnect: false });
  }

  init(): void {
    this.socket.connect();
    this.searchResultStatus$ = fromEvent<string>(this.socket, 'TOURS_SEARCH_SEARCH_RESULT_STATUS').pipe(this.toJSON());
    this.connect$ = fromEvent(this.socket, 'connect');
    this.disconnect$ = fromEvent(this.socket, 'disconnect');
  }

  join(searchId: string): void {
    this.socket.emit('join', 'CUSTOMER_TOUR_SEARCH_ID', searchId);
  }

  leave(searchId: string): void {
    this.socket.emit('leave', 'CUSTOMER_TOUR_SEARCH_ID', searchId);
  }

  private toJSON<R>(): OperatorFunction<string, R> {
    return result => result.pipe(map<string, R>(result => JSON.parse(result)));
  }
}
