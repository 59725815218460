import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

@Component({
  selector: 'app-accommodation-icon',
  templateUrl: './accommodation-icon.component.html',
  styleUrls: ['./accommodation-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationIconComponent {
  @Input() value = ToursSearch.Accommodation.Double;
}
