import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ToursSearch } from '@temabit/package-types';

@Component({
  selector: 'app-transport-icon',
  templateUrl: './transport-icon.component.html',
  styleUrls: ['./transport-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportIconComponent {
  @Input() value = ToursSearch.Transport.No;
}
