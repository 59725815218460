import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxGalleryAnimation, NgxGalleryOptions } from '@kolkov/ngx-gallery';

import { Location, ToursSearch } from '@temabit/package-types';
import { Required } from '@temabit/package-utils';

import { GalleryLinks } from '@helpers/images.helper';

import { NormalizedParams, NormalizedSearch } from '@interfaces/modules/tours/search';

@Component({
  selector: 'app-hotel-card',
  templateUrl: './hotel-card.component.html',
  styleUrls: ['./hotel-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelCardComponent implements OnChanges {
  @Required @Input() hotelId!: number;
  @Input() galleryImages: GalleryLinks[] = [];
  @Input() hotelUrl: string = '';
  @Input() hotelName: string = '';
  @Input() stars: string | number = 0;
  @Input() rating: number = 0;
  @Input() countryName: string = '';
  @Input() resortName: string = '';
  @Input() location?: Location;

  @Input() offer?: ToursSearch.Offer;

  // These fields have a higher display priority than offer @input
  @Input() food?: ToursSearch.Food;
  @Input() accommodation?: ToursSearch.Accommodation;
  @Input() transport?: ToursSearch.Transport;
  @Input() room?: string;
  @Input() priceUAH?: string;

  queryParams: Params = { };
  options: NgxGalleryOptions[] = [{
    thumbnails: false,
    preview: false,
    imageAnimation: NgxGalleryAnimation.Slide
  }];

  constructor(
    private readonly modalService: NgbModal,
    private readonly route: ActivatedRoute,
    private readonly injector: Injector,
    private readonly cd: ChangeDetectorRef
  ) { }

  openMap(map: TemplateRef<any>): void {
    if (this.isDisplayableLocation()) {
      this.modalService.open(map, { centered: true, size: 'xl', injector: this.injector });
    }
  }

  ngOnChanges({ hotelId }: SimpleChanges): void {
    if (hotelId && hotelId.previousValue !== hotelId.currentValue) {
      this.queryParams = this.patchParamsByHotelDestination(this.route.snapshot.queryParams as NormalizedParams);
      this.cd.markForCheck();
    }
  }

  private isDisplayableLocation(): boolean {
    if (this.location) {
      const { zoom, coordinates: [lat, lng] } = this.location;

      return zoom !== 0 || lat !== 0 || lng !== 0;
    }

    return false;
  }

  private patchParamsByHotelDestination({ searchId, did, dt, h, rs, ...rest }: NormalizedParams): Partial<NormalizedSearch> {
    return { ...rest, did: this.hotelId.toString(), dt: ToursSearch.DestinationType.Hotel };
  }
}
