import { Injectable } from '@angular/core';
import { Store } from 'rxjs-observable-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ToursCatalog } from '@temabit/package-types';

import { InformationState } from './information.state';
import { ToursSearchEndpoint } from '../api/tours-search.endpoint';
import { onLangChange } from '@helpers/language.helper';

import { PageInfo, ResortPageInfo } from '@interfaces/services/api/tours-catalog.endpoint';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class InformationStore extends Store<InformationState> {
  resortPageInfo$: Observable<ResortPageInfo> = this.onChanges('pageInfo')
    .pipe(
      filter((val): val is ResortPageInfo => val?.type === ToursCatalog.PageType.Resort)
    );

  constructor(
    private readonly toursSearchEndpoint: ToursSearchEndpoint,
    private readonly translate: TranslateService
  ) {
    super(new InformationState());
  }

  init(): void {
    this.loadDepartureCities();
  }

  setPageInfo(pageInfo: PageInfo): void {
    this.patchState(pageInfo, 'pageInfo');
  }

  dropPageInfo(): void {
    this.patchState(undefined, 'pageInfo');
  }

  private loadDepartureCities(): void {
    onLangChange(this.translate)
      .pipe(
        switchMap(lang => this.toursSearchEndpoint.getDepartureCities(lang)),
        untilDestroyed(this)
      )
      .subscribe(cities => this.patchState(cities, 'departureCities'));
  }
}
