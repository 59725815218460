import { addDays, isBefore, min, set, subDays } from 'date-fns';

import { ToursSearch } from '@temabit/package-types';

import { NumberRange } from '@classes/range/number-range';

import { ScrollDirection } from '@modules/tours/table-toolkit/interfaces/scroll';

export class NightDateScrollPosition {
  private startDateValue: Date;
  private startNightsValue: number;
  private readonly availableNights: NumberRange = new NumberRange(1, 30);
  private readonly minDate: Date = this.generateMinDate();

  constructor(offers: ToursSearch.Offer[], private step: number) {
    this.startDateValue = this.defaultStartDate(offers);
    this.startNightsValue = this.defaultStartNight(offers);
  }

  get startDate(): Date {
    return this.startDateValue;
  }

  get startNights(): number {
    return this.startNightsValue;
  }

  scroll(direction: ScrollDirection): void {
    switch (direction) {
      case 'left':
        this.setDate(subDays(this.startDateValue, this.step));
        break;
      case 'right':
        this.setDate(addDays(this.startDateValue, this.step));
        break;
      case 'up':
        this.setNights(this.startNightsValue - this.step);
        break;
      case 'down':
        this.setNights(this.startNightsValue + this.step);
        break;
    }
  }

  center(date: Date, nights: number): void {
    const offset: number = Math.floor(this.step / 2);
    this.setDate(subDays(date, offset));
    this.setNights(nights - offset);
  }

  private setDate(startDate: Date): void {
    if (isBefore(startDate, this.minDate)) {
      this.startDateValue = new Date(this.minDate);
    } else {
      this.startDateValue = startDate;
    }
  }

  private setNights(startNights: number): void {
    if (startNights < this.availableNights.from) {
      this.startNightsValue = this.availableNights.from;
    } else if (startNights + this.step - 1 > this.availableNights.to) {
      this.startNightsValue = this.availableNights.to - this.step + 1;
    } else {
      this.startNightsValue = startNights;
    }
  }

  private generateMinDate(): Date {
    const cleaned: Date = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    return addDays(cleaned, 1);
  }

  private defaultStartNight(offers: ToursSearch.Offer[]): number {
    return Math.min(...offers.map(offer => offer.nights));
  }

  private defaultStartDate(o: ToursSearch.Offer[]): Date {
    const dates: Date[] = o.map(offer => offer.dateRange.from);

    return min(dates);
  }

}
