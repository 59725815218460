import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ToursCatalog } from '@temabit/package-types';

import { valueAccessor } from '@helpers/angular.helper';

@Component({
  selector: 'app-search-hotels-picker',
  templateUrl: './search-hotels-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessor(SearchHotelsPickerComponent)]
})
export class SearchHotelsPickerComponent implements ControlValueAccessor {
  @Input() hotels: ToursCatalog.SelectiveHotel[] = [];
  @Input() placeholder: string = '';

  private selectedHotelsValue: number[] = [];
  private onTouched: Function = () => { };
  private onChange: Function = () => { };

  get selectedHotels(): number[] {
    return this.selectedHotelsValue;
  }

  set selectedHotels(selectedHotels: number[]) {
    this.selectedHotelsValue = selectedHotels;
    this.onTouched();
    this.onChange(selectedHotels);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  writeValue(hotelsCode: number[]): void {
    this.selectedHotelsValue = cloneDeep(hotelsCode);
  }
}
