import { Images, ToursCatalog } from '@temabit/package-types';

import { generateRequestState } from '@helpers/endpoint.helper';

import { Hotel, RawSeoLinkingLink } from '@interfaces/services/api/tours-catalog.endpoint';
import { DisplayableHotelReview } from '@interfaces/hotel-review';

export interface LinkingState {
  popularCountries: RawSeoLinkingLink[];
  resorts: RawSeoLinkingLink[];
  hotels: RawSeoLinkingLink[];
}

export type HotelRating = Pick<ToursCatalog.SiteHotel['reviews'], 'count' | 'rating' | 'votes'>;
export type HotelFacility = { title: ToursCatalog.DescriptionType, text: string, services: ToursCatalog.Service[] };

export class HotelPageInfoState {
  rating?: HotelRating;
  facilities: HotelFacility[] = [];
  reviews: DisplayableHotelReview[] = [];
  isLastReviewsPage = false;
  commonDescription?: string;
  hotelInfo?: Hotel;
  images: Images.ResizesLinks[] = [];
  requests = {
    [ToursCatalog.Actions.Name.SiteGetHotelReviews]: generateRequestState()
  };
}
